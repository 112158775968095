@import '../../../constants/Colors.scss';

.FeeinfoContainer{
    width: 100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    padding: 20px 15px;
}

.TitleContainer{
    width: 100%;   
}

.FeeContainer{
    width: 100%; 
    padding-top:15px;
    padding-bottom:10px;
    display:flex;
    flex:1;
    flex-direction:row;
    justify-content: space-between;
    border-bottom: 1px solid $contentFontTintColor;
}