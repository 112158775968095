@import '../../../constants/Colors.scss';

.SegLeftBtn {
    flex:2; 
    display: flex;
    flex-direction:row;
    justify-content:center; 
    align-items:center;
    border-top-left-radius:10px;
    border-bottom-left-radius:10px;
    border-color:$primaryButtonBGColor;
    height:44px;
}

.SegMiddleBtn {
    flex:2;
    display: flex; 
    flex-direction:row;
    justify-content:center; 
    align-items:center;
    height:44px;
}

.SegRightBtn {
    flex:2;
    display: flex; 
    flex-direction:row;
    justify-content:center; 
    align-items:center;
    border-top-right-radius:10px;
    border-bottom-right-radius:10px;
    height:44px;
}