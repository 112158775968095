@import '../../constants/Colors.scss';

.Catalog {
    width: 100%;
}

.CatalogView {
    min-width: 320px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin:  0 auto;
    padding: 20px 0px 20px 7px;    
}

.SubCatMenuBox {
    width: 100%;
    background-color: $primaryMessageBGColor;
    border-bottom:1px solid $contentFontTintColor;
    overflow: auto;     
}

.SubCatButtonContainer{
    padding:5px 10px;
    width: 100%;
    margin: 0 auto;
    display: flex; 
    flex-direction: row;
    flex-wrap: wrap;
    height: 95px; 
}

@media (min-width: 321px) {
    .SubCatButtonContainer{
        width: 90%;
        padding:5px 0;
    }
    .CatalogView {
        max-width: 320px;
        padding: 20px 0px;
    }    
}

@media (min-width: 321px) {
    .CatalogView {
        max-width: 320px;
    }    
}

@media (min-width: 500px) {
    .CatalogView {
        max-width: 360px;
    }    
}

@media (min-width: 681px) {
    .CatalogView {
        max-width: 540px;
    }    
}

@media (min-width: 750px) {
    .CatalogView {
        max-width: 730px;
    }    
}

@media (min-width: 1024px) {
    .CatalogView {
        max-width: 950px;
    }    
}

@media (min-width: 1300px){
    .SubCatButtonContainer{
        height: 50px; 
    }   
}
