@import '../../../constants/Colors.scss';

.ServiceBoxContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items:center;
    padding: 15px;
}

.TitleContainer{
    width: 100%;   
}

.ServiceContainer{
    width: 100%; 
    display: flex;
    flex-direction:row;
    flex-wrap:wrap;
    flex:1;
    justify-content: space-between;
    padding-top:15px;
    padding-bottom:5px;
}

.OptionContainer{
    width: 100%;  
    padding-top:15px;
    padding-bottom:0px;
    flex:1;
    justify-content: space-between
}

.BaseItem{
    display: flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:49%;
    height:46px;
    padding-top:2px;
    padding-bottom:2px;
    margin-bottom:15px;
    border-radius:23px;
    border-width:1.5px;
}

.ChargeMethod{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:flex-start;
    width:49%;
    height:40px;
    padding:5px;
    padding-left:10px;
    margin-bottom:15px;    
}