@import '../../../constants/Colors.scss';

.ReviewDataContainer {
    display: flex;
    flex-direction:column;
    flex:1; 
    justify-content:space-around;
    align-items:flex-start;
}

.ReviewScoreContainer {
    display: flex;
    flex-direction:row; 
    width:100%; 
    align-items:center; 
    justify-content:space-between
}

.ReviewHeartContainer {
    display: flex;
    width:60%; 
    flex-direction:row;
    align-items:flex-start;
    justify-content:space-between
}

.TextStyle {
    color:$contentFontColor;  
}