.NavigationItems {
    margin: 0;
    padding: 0;
    display:flex;
    list-style: none;
    flex-direction: column;
    flex-flow: column-reverse;
    justify-content: flex-end;
    align-items:center;
    height: 100%;
}

.NavigationItem  {
    display: block;
    color: gray;
    margin: 6px;
    font-weight: 700;
    font-size: 0.85rem;
    text-decoration: none; 
    padding: 0;
}

.NavigationItem:hover,
.NavigationItem:active,
.NavigationItem.active {
    cursor: pointer;
    color: #44A7CB;
} 

.Btn {
    margin: 6px;
    display: inline-block;
    text-decoration: none; 
    color: gray; 
    padding: 5px 8px;
    border: 1px solid  gray;
    border-radius: 5px; 
    font-weight: 700;
    font-size: 0.8rem;
    text-align: center;
    background-color:#ffffff; 
}

.Btn:hover, 
.Btn:active,
.Btn.active 
{
    color: #ffffff; 
    border: 1px solid #44A7CB;
    background-color:#44A7CB; 
}

@media (min-width: 530px) {
    .NavigationItems{
        flex-direction:row;
    }
    .NavigationItem{
        margin: 0px 5px;
        border-bottom: none;
    }
    .Btn {
        margin: 0px 5px;  
    }
}