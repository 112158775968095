@import '../../../constants/Colors.scss';

.MenuContainer {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 18px 0 rgba(0,0,0, 0.2), 0 7px 10px -5px rgba(0,0,0, 0.2);
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  height: 45px;
  width: 100%;
  min-width: 300px;
  color: $white;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  z-index: 20;
  list-style: none;
}

.MenuItem {
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

.MenuItem:hover {
  cursor: pointer;
}

.MenuLink {
  font-size: 11px;
  font-weight: bold;
  text-decoration: none;
  color: $contentFontColor;
}

.Active {
  color: $orange;
}

.MenuBorder{
  width: 60px;
  margin-top: 5px;
}

@media (min-width: 530px) {

  .MenuContainer{
    height: 55px;
  }

  .MenuItem {
    width: 100px;
  }

  .MenuBorder{
    width: 100px;
    margin-top: 6px;
  }

  .MenuLink {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .MenuContainer{
    height: 65px;
  }

  .MenuLink {
    font-size: 14px;
  }

  .MenuItem {
    width: 145px;
  }

  .MenuBorder{
    width: 145px;
    margin-top: 8px;
  }
}

@media (min-width: 1024px) {
  .MenuContainer{
    height: 90px;
  }

  .MenuItem {
    width: 180px;
  }

  .MenuItem:hover {
    opacity: 0.5;
  } 

  .MenuBorder{
    width: 180px;
  }

  .MenuLink {
    font-size: 20px;
  }
}

@media (min-width: 1500px) {
  .MenuContainer{
    height: 100px;
  }

  .MenuBorder{
    margin-top: 10px;
  }

  .HeroText{
    width: 800px;
  }
}