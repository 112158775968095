.ArticleTitle {
  padding-left:8px;
  padding-right:8px;  
}

.ArticleP {
  font-size: 1rem;
  line-height: 1.75rem;
  padding: 0.5rem 0;
}

.ArticleUl {
  font-size: 0.85rem;
  line-height: 1.5em;
  padding: 0 0.5em;
  margin-left: 1rem;
}

.ArticleLi {
}

.ArticleImg {
  width: 90% !important;
  margin: auto auto;
}

.BodyLayout  {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.BodyLeftBox {
  width: 100%;
  padding-left:8px;
  padding-right:8px;
  padding-top: 20px;
}

.BodyRightBox {
  width: 100%;
  padding-top: 20px;
}

.PodImageSmall {
  width:150px;
  height:150px;
}

.PodImgSmall {
  width: 100%;
  height: 100%;
}

.PodImageSmallMargin {
  margin-top: 0px;
}

.SNSButtonGroup {
  padding: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.DownloadContainer{
  width: 100%;
  padding: 20px 0;
}

.PodSmallRegularLayout {
  width: 100%;
  display: flex;
  flex-direction: column;    
}

.PodSmallRegularBox {
  width: 100%;
}

@media (min-width: 530px) {
  .PodImageSmall {
    width:220px;
    height:220px;
  }
  .ArticleImg {
    max-width: 500px;
  }
}

@media (min-width: 768px) {
  .ArticleTitle {
    padding-left:0px;
    padding-right:0px;  
  }
  
  .BodyLayout{
    flex-direction: row;  
  }
  .BodyLeftBox {
    width: 60%;
    padding-left:0px;
    padding-right: 20px;
  }
  
  .BodyRightBox {
    width: 40%;
  }

  .PodImageSmall {
    width: 100%;
    height: auto;
  }

  .PodImageSmallMargin {
    margin-top: -100px;
  }

  .SNSButtonGroup {
    justify-content: flex-start;  
  }

  .PodSmallRegularLayout {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
        
  }
  
  .PodSmallRegularBox {
    width: 30%;
    margin-bottom: 1em;
  }
  
}