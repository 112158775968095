@import '../../constants/Colors.scss';

.MenuContainer {
    height: 70px;
    width: 100%;
    min-width: 320px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-bottom:1px solid $contentFontTintColor; 
    background-color: $mainMenuContainerBgColor;
}

.MenuItemContainer{
    width: 65px;
    height: 50px;
    position: relative;
    color: $mainMenuInActiveTextColor;
    background-image: url(../../assets/images/tag_inactive_65x50.svg);
    transition: 0.3s;   
}

.HELP {
    background-image: url(../../assets/images/tag_help_65x50.svg);   
}

.TEACH {
    background-image: url(../../assets/images/tag_teach_65x50.svg);   
}

.MAKE {
    background-image: url(../../assets/images/tag_make_65x50.svg);   
}

.RENT {
    background-image: url(../../assets/images/tag_rent_65x50.svg);   
}

.Active {
    color: $white;
}

.MenuItemContainer:hover {
    cursor: pointer;
    transform: scale(1.15)
}

.MenuButtonTextContainer{
    position: absolute;
    width:65px;
    height:12px;
    top: 30px;
    right: -5px;
    display: flex;
    flex-direction: column;
    align-items: center
}

.MenuIconContainer{
    position: absolute;
    padding-top: 5px;
    bottom: 17px;
}

.MenuText{
    font-size:12px; 
    font-weight:bold
}