@import '../../../constants/Colors.scss';

.MainCatTitleContainer{ 
    border-top:1px solid $contentFontTintColor; 
    width:100%;
    padding:10px 0; 
    padding-top:20px; 
    margin-top:0;
    display: flex; 
    flex-direction:row; 
    justify-content:space-between; 
    align-items:center;
}

.MainCatTitleContainer:first-child{
    border-top:none;     
}

.CatTitleText{ 
    color:$contentFontColor; 
    font-size:17px;
}

.CatTitleText:hover, .SeeMoreButton:hover{
    cursor: pointer;
    opacity: 0.7;
}

.CatTextBold{
    font-weight:bold;
    color:$primaryButtonBGColor;
    padding-right: 5px;
}

.SeeMoreButton{ 
    width: 80px;
    height: 16px;
    display: flex;
    flex-direction:column;
    justify-content:flex-end;
    align-items:center;
}

.SeeMoreText{
    font-weight:bold;
    color:$primaryButtonBGColor;
    font-size:12px;
}

.Slider {
    min-height:105px;
    margin-bottom:25px;
    width:100%;
    min-width: 304px;
    max-width: 304px;
    overflow: hidden;
    position:relative;
}   

.GigThumbnailView{
    position: absolute;
    margin:0;
    height:105px;
    display: flex; 
    flex-direction: row;
    width: 950px;
    left:0;
    overflow: hidden;
    transition: left 0.3s ease;
}

.Prev, .Next {
    cursor: pointer;
    position: absolute;
    text-decoration: none;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 10px 8px;
    color: #000;
    font-weight: bold;
    font-size: 14px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    border:none;
    user-select: none;
    mix-blend-mode: screen;
    background-color: $white;
    opacity: 0.7;
}
  
.Next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.Prev:hover, .Next:hover {
    opacity: 1;
}

@media (min-width:321px){
    .Slider{
        max-width: 100%;
    }   
}

@media (min-width:1045px){
    .Prev, .Next{
        display: none;
    }    
}
