@import "../../../constants/Colors.scss";

.Spinner,
.Spinner:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}

.Spinner {
  margin: 0 auto;
  font-size: 10px;
  border: solid 1px black;
  text-indent: -9999em;
  border-top: 0.21em solid $contentFontTintColor;
  border-right: 0.21em solid $contentFontTintColor;
  border-bottom: 0.21em solid $contentFontTintColor;
  border-left: 0.21em solid $white;
  transform: translateZ(0);
  animation: rotateAnim 1.1s infinite linear;
}

@keyframes rotateAnim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}