.SideDrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height:100%;
    left: 0;
    top:0;
    z-index:300;
    padding: 10px 0;
    background-color: #e7f1f8;
    transition: transform 0.3s ease-out;
}

.Brand {
    display:flex;
    justify-content: center;
    width:100%;
    margin: 20px 0 15px 0;
} 

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}


@media (min-width: 530px) {
    .SideDrawer {
        display: none;
    }
}

@media print {
    .SideDrawer {display:none}    
}