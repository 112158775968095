.SecurityCode {
  margin-top: 10px;
  width:100%;
  max-width: 600px;
  min-width: 320; 
}

.CVVContent {
  margin-top: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.CVVItem {
  width: 90%;
  margin-bottom: 10px;
  text-align:center;
}

.Title {
  display: block;
  margin-bottom: 8px;
}

.ScreenShot {
  width: 90%;
  max-width:250px;
}

@media (min-width: 530px) {
  .CVVContent {
    flex-direction: row;     
  }
  .CVVItem {
    width: 45%;
  }
}
