@import '../../../constants/Colors.scss';

.SectionTag {
  background-image: url(../../../assets/images/tag_blog_back.svg), url(../../../assets/images/tag_blog_head.svg);
  background-position: left top, right top;
  background-repeat: no-repeat;
  height: 20px;
  width: 42px;
}

.SectionTagText {
  font-size: 10px;
  font-weight: bold;
  margin: 0 10px 0 10px;
  height: 100%;
  width: 22px;
  color: $white;
  line-height: 18px;
  background-color: $orange;
}

.MoreLink {
  height: 100%;
  color: $primaryButtonBGColor;
  text-decoration: none;
  font-size: 0.95rem;
  flex: 5;
  text-align: right;
  align-self: flex-end;
  margin-right:10px;
}

.MoreLink:hover {
  opacity: 0.5;
}

.SectionMargin {
  margin-top: 40px;
}

.SectionHead {
  margin-left: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.SectionHeadText{
  padding-left:10px;
  font-size: 15px;
  color: $contentFontColor;
}

@media (min-width: 375px) {
  .SectionHead {
    margin-left: 0px;
  }

  .MoreLink {
    margin-right:0px;
  }
}

@media (min-width: 530px) {
}

@media (min-width: 768px) {
  /* section tag header */
  .SectionTag {
    height: 40px;
    width: 80px;
  }
  .SectionTagText {
    font-size: 18px;
    margin: 0 20px 0 20px;
    width: 40px;
    line-height: 35px;
  }

  .SectionHead {
    margin-left: 0px;
  }

  .SectionHeadText{
    padding-left:15px;
    font-size: 22px;
  }
  /* section tag header */
}

@media (min-width: 1024px) {
  /* section tag header */
  .SectionTag {
    height: 45px;
    width: 90px;
  }

  .SectionTagText {
    font-size: 20px;
    margin: 0 22.5px 0 22.5px;
    width: 45px;
    line-height: 40px;
  }

  .SectionMargin {
    margin-top: 70px;
  }

  .SectionHeadText{
    padding-left:15px;
    font-size: 27px;
  }
  /* section tag header */
}

@media (min-width: 1500px) {
  .SectionTag {
    height: 50px;
    width: 120px;
  }

  .SectionTagText {
    font-size: 24px;
    margin: 0 25px 0 25px;
    width: 70px;
    line-height: 45px;
  }

  .SectionMargin {
    margin-top: 100px;
  }

  .SectionHeadText{
    padding-left:15px;
    font-size: 27px;
  }
  /* section tag header */  
}