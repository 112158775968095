

@import '../../../constants/Colors.scss';

.UserInformation { 
    display: flex;
    flex-direction: row; 
    width: 100%; 
    height: 90px;
    border-top: 1px solid $contentFontTintColor;
    border-bottom: 1px solid $contentFontTintColor;
}

.AvatorContainer{
    display: flex;
    flex-direction: column;
    width:90px; 
    height:90px;
    border-right: 1px solid $contentFontTintColor; 
    align-items:center; 
    justify-content:center; 
}

.AvatorContainer:hover{
    cursor: pointer;
    opacity: 0.5;
}

.UserInfoBox {
    display: flex;
    flex-direction: column;
    flex:1; 
    height:90px; 
    justify-content:center; 
    align-items:center; 
}

.UserInfoDataHead {
    display: flex;
    flex-direction: column; 
    align-items:center; 
    justify-content:center; 
    width:100%; 
    padding: 5px 0;
    border-bottom: 1px solid $contentFontTintColor; 
}

.UserInfoData {
    display:flex;
    flex:1;
    align-items:center;
    justify-content:center
}

.UserInfoData:hover {
    cursor: pointer;
    opacity: 0.5;
}

.BodyTextSmall {
    font-size:0.75em; 
    color:$contentFontColor;
}

.Bold {
    font-weight:bold
}