@import '../../constants/Colors.scss';

.DaySchedule{
    border-width:2px;
    border: 2px solid $contentFontTintColor; 
    border-radius:5px;
    margin-bottom:10px; 
}
.ScrollContainer {
    width: 100%;
    height: 146px;
    border-top: 1px solid $contentFontTintColor; 
    // border-bottom-left-radius:3px;
    // border-bottom-right-radius:3px;
    overflow: auto;
    white-space: nowrap;
}
.DayContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height:30px;
    justify-content: center;
    align-items: center;
    background-color:$yellow;
    border-top-left-radius:3px;
    border-top-right-radius:3px;
}
.Row{
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center
}
.TimeSheet{
    position: relative;
    background-color:$primaryButtonBGColorLight
}
.TimeColum {
    display: flex;
    flex-direction: column;
    min-width: 100px;
    width:100px;
    height:30px;
    justify-content: center;
    align-items: center
}
.TimeText {
    font-size:11px;
    font-weight:bold;
    color: $contentFontTintColor
}
.TimeLineColum {
    display: flex;
    flex-direction: column;
    min-width: 100px;
    width:100px;
    height:100px;
    justify-content: center;
    align-items: center;
    background-color: $contentBGColor;
}
.Timeline {
    width:1px;
    height:100%;
    border-left:1px solid $tintColorBGColor;
    background-color: $tintColorBGColor;
}
.Shift{
    position:absolute;
    border-radius: 5px;
    border: 2px solid $tintColorBGColor; 
    background-color:$white; 
    height:70px
}
.ShiftHourText {
    padding:2px 5px; 
    font-size:0.65rem;
    color:$contentFontColor
}
.RemainingText{
    font-size:24px;
    font-weight:bold;    
}
.FreeZone {
    border: 1.5px solid $primaryButtonBGColor;
    border-radius:3px; 
    width:15px;
    height:15px;
    background-color:$white;
}
.ParcialyFree {
    border: 1.5px solid $yellow;
    border-radius:3px;
    width:15px;
    height:15px;
    background-color:$white;
}
.NotFree{
    border-radius:3px;
    width:15px;
    height:15px;
    background-color:$tintColorBGColor
}