$tabBGcolor: #3a4a62;
$tabTextColor: #ffffff;
$tabIconColor: #ffffff;
$tabTintColor: #c2c4c7;
$navBGColor: #45a7cb;
$navIconColor: #ffffff;
$navTextColor: #ffffff;
$navTintColor: #c2c4c7;
$primaryMessageBGColor: #bee4f3;
$secondaryMessageBGColor: #dfdfdf;
$contentFontColor: #5c6572;
$contentFontTintColor: #b2b2b7;
$tintColorBGColor: #dfdfdf;
$contentBGColor: #f6f8fa;
$contentGrayBG: #f5f5f5;
$mainMenuContainerBgColor: #ffffff;
$mainMenuBgColorHELP: #e87473;
$mainMenuBgColorTEACH: #aaa5ea;
$mainMenuBgColorRENT: #fdbe57;
$mainMenuBgColorMAKE: #59cbc8;
$mainMenuTextColor: #ffffff;
$mainMenuInActiveIconColor: #425063;
$mainMenuInActiveTextColor: #425063;
$mainMenuBgColorInActive: #dde1e3;
$mainMenuIconColor: #ffffff;
$redColor: #ff0000;
$redColorTint: #ff000038;
$attention: #fc6769;
$attentionTint: #fc676a60;
$primaryButtonBGColor: #45a7cb;
$primaryButtonTextColor: #ffffff;
$primaryButtonBGColorLight: #9cbac5;
$primaryButtonBGColorTint: #45a7cb60;
$secondaryButtonBGColor: #ececec;
$secondaryButtonTextColor: #45a7cb;
$dropDownShadow:rgba(0,0,0,0.5); 
$white: #ffffff;
$black: #000000;
$heart: #fc6769;
$blue: #0000ff;
$darkBlue: #31728a;
$pink: #e87473;
$yellow: #fdbe57;
$orange: #F7921E;
$yellowLight: #ffe1b1;
$green: #16c72e;
$greenTint: #16c72e38;
$lineBlue: "#90adb9";
$tintBlue: #bee4f3;
$tintYellow: "#ffdda6";