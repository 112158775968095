@import "../../constants/Colors.scss";

.CategoryListContainer {
    width: 90%;
    overflow-y: auto;
    max-height: 90%;
    border-radius: 5px;
    padding:8px 8px 20px 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    background-color: $white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 400;
}

.CloseBtnContainer{
    width:100%;
    display:flex;
    flex-direction:row; 
    height:30px; 
    align-items:center; 
    justify-content:flex-end;
    padding:10px 0px;
}

.Open {
    display: block;
}

.Close {
    display: none;
}

.CatBoxContainer{
    display:flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction:row; 
    justify-content:flex-start; 
    align-items:flex-start; 
    margin: 0 auto;
}

.ManCatBoxItem {
    width:100%;
    margin: 15px 10px;
}

.MainCatTitle{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding:8px 0 8px 8px;
    border-radius: 5px 5px 0 0;
    margin: 0 auto;
}

.MainCatTitle:hover{
    cursor: pointer;
    opacity: 0.5;
}

.CatIcon{
    font-size: 0.75rem;
    color:$white;
    margin-right:10px;
}

.SubCatText{
    font-size: 0.85rem;
    color:$contentFontColor;
}

.SubCatBox{
    width: 100%;
    background-color: $contentBGColor;
    border-radius: 0 0 5px 5px;
}

.SubCatTitle{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding:8px 8px 8px 20px;   
}

.SubCatTitle:last-child{
    border-radius: 0 0 5px 5px;    
}

.SubCatTitle:hover {
    cursor: pointer;
    background-color: $primaryButtonBGColorTint;
    border-radius: 0 0 5px 5px;    
}

@media (min-width: 500px) {
    .ManCatBoxItem {
        max-width: 45%;
    }
    .CategoryListContainer{
        max-width: 660px;
    }
}

@media (min-width: 1500px) {
    .ManCatBoxItem {
        max-width: 22%;
    }
    .CategoryListContainer{
        max-width: 1400px;
    }
}