.SideMenu {
    position: absolute;
    min-width: 290px;
    max-width: 90%;
    left: 0;
    top:0;
    z-index:200;
    background-color:white;
    padding: 10px 0px 10px 10px;
    transition: transform 0.3s ease-out;
}

.MenuTitle {
    margin-left:5px;
    font-size: 1em;
    font-weight: bold;
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}

@media (min-width: 600px) {
    .SideMenu {
        display: none;
    }
}