.Hero1 {
    background-image: url(../../../assets/images/hero_fma_1.jpg);    
}

.Hero2 {
    background-image: url(../../../assets/images/hero_fma_2.jpg);    
}

.Hero3 {
    background-image: url(../../../assets/images/hero_fma_3.jpg);    
}

.Hero4 {
    background-image: url(../../../assets/images/hero_fma_4.jpg);    
}

.Hero5 {
    background-image: url(../../../assets/images/hero_fma_5.jpg);    
}

.Hero6 {
    background-image: url(../../../assets/images/hero_fma_6.jpg);    
}

.Slider {
    padding:0;
    margin:0;
    height: 240px;
    min-width: 320px;
}

.ImageBox {
    position: relative;
    width: 100%;
    margin: 0;
    left:0;
    overflow: hidden;
    transition: left 2s ease;
}

.HeroImage {
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 240px;
    width: 100%;
    min-width: 320px;
    float: left;
    margin: 0;
    padding: 0;
}

.HeroText {
    width:305px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #444141;
}

.TagLine {
    font-size:15px
}

.SubTagLine {
    margin-top: 20px;
    font-size:13px    
}

.Downloads {
    display:none;
    width:305px;    
}

.BtnContainer {
    top: -26px;
    position: relative;
    text-align: center;
}

.SliderBtn {
    display: inline-block;
    height: 8px;
    width: 8px;
    margin: 0 15px 0 0;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.38);
    transition: background-color 0.6s ease;
}

.Active, .SliderBtn:hover{
    cursor: pointer;
    background-color: rgba(255, 255, 255, 1);
}

.ArrowBtnContainer {
    top: -65%;
    position: relative;
    text-align: center;
    width:100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
}

.Prev, .Next {
    cursor: pointer;
    position: relative;
    top: 50%;
    width: auto;
    margin-top: -12px;
    padding: 8px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    text-decoration: none;
}

.Next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.Prev:hover, .Next:hover {
    background-color: rgba(68, 167, 203, 0.8);
}
  

.Disable {
    color: rgba(255, 255, 255, 0);    
}

.MobileOnly {
    display: block;
    background-color: #ffffff;
}    

.DownloadContainer{
    width: 70%; 
    min-width: 304px;
    max-width: 380px;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .Slider {
        height: 360px;
    }
    .HeroImage {
        height: 360px;
    }
    .HeroText{
        width: 480px;
    }
    .TagLine {
        font-size:22px
    }
    .SubTagLine {
        margin-top: 20px;
        font-size:19px    
    }
    .Prev, .Next {
        margin-top: -22px;
        padding: 16px;
        font-size: 20px;    
    }
    .ArrowBtnContainer {
        top: -60%;
    }
    .SliderBtn {
        height: 12px;
        width: 12px;
        margin: 0 20px 0 0;
        border-radius: 50%;
    }
    .BtnContainer {
        top: -26px;
        position: relative;
        text-align: center;
    }
    .Downloads {
        margin-top: 20px;
        display:block;
        width:360px;    
    }
    .MobileOnly {
        display: none;
    } 
}

@media (min-width: 1024px) {
    .HeroText{
        width: 720px;
    }
    .TagLine {
        font-size:26px
    }
    .SubTagLine {
        margin-top: 30px;
        font-size:23px    
    }
    .Downloads {
        margin-top: 30px;
        display:block;
        width:400px;    
    } 
}

@media (min-width: 1500px) {
    .Slider {
        height: 400px;
    }
    .HeroImage {
        height: 400px;
    }
    .HeroText{
        width: 800px;
    }
    .TagLine {
        font-size:31px
    }
    .SubTagLine {
        margin-top: 30px;
        font-size:26px    
    }
    .Downloads {
        width:450px;    
    } 
}
