@import '../../constants/Colors.scss';

.SubCatButton {
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction:row;
    height:30px;
    margin:5px;
    margin-right: 10px;
    border:1px solid $primaryButtonBGColor;
    background-color: $white;
    color: $primaryButtonBGColor;
    position: relative;
}

.SubCatButton:hover  {
    color: $white;
    background-color: $primaryButtonBGColor;  
}

.SubCatSelected {
    color: $white;
    background-color: $primaryButtonBGColor;  
}

.ButtonText {
    padding: 0 0.5rem;
    font-size: 0.75rem;
    font-weight: bold
}

.Budge{
    display:flex;
    position:absolute;
    top:3px;
    right:3px;
    height:0.75rem;
    min-width:0.75rem;
    border-radius:50%;
    justify-content:center;
    align-items:center;
    background-color:$redColor;
}
.BudgeText{
    font-size:0.45rem;
    font-weight:bold;
    color:$white
}