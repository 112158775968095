.ToggleMenu  {
    font-size: 36px;
    width: 36px;
    color: gray;
    display: none;
    background: transparent;
    padding-bottom:5px;
    border:none;
    text-decoration: none;
}

@media (max-width: 530px) {
    .ToggleMenu {
        display: block;
    }
}
