.MainContent {
    width: 320px;
    padding: 15px 15px 5px 15px;
    margin:0px auto;
    background: #41BEEB;
    text-align: center;
}

.MainContent h2 {
    font-weight: bolder;
    color: white;
    margin-bottom: 15px;
}

.MainContent h5 {
    font-weight: normal;
    font-size: 1rem;
    color: white;
    line-height: 1.8rem;
}

.MainContentImage {
    margin: 0;
}