@import "../../constants/Colors.scss";

.DownloadAppContainer {
    width: 300px;
    overflow-y: auto;
    padding:10px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    background-color: $contentBGColor;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 400;
}

.CloseBtnContainer{
    width:100%;
    display:flex;
    flex-direction:row; 
    height:30px; 
    align-items:center; 
    justify-content:flex-end;
}

.Open {
    display: block;
}

.Close {
    display: none;
}

.DownloadBtnBox{
    display:flex;
    flex-direction: row;
    margin:0 auto;
    padding:20px 10px;
    justify-content: center;
    align-items: center;
    width: 280px
}

.BtnStyle {
    width: 120px;
    text-decoration: none;
}

.BtnStyle:hover {
    opacity: 0.5;
}

.DownLoadBtn{
    width: 100%;
}