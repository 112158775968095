.StepHead, .StepHeadLeft {
    width: 60%;
    min-width: 152px;
    max-width: 192px; 
    display: flex;
    padding-bottom: 15px;
}

.StepHeadLeft {
    margin-left:auto;    
}

.StepHeadContent {
    flex:1;
    align-self: flex-end;
    background-color:#f5f5f5;   
}

.HeadBox {
    width: 90%;
    margin: 0px auto;
    padding: 8px;
    background-color:#fff;
    border-radius: 8px;
}


@media (min-width:1024px) {
    .StepHead, .StepHeadLeft {
        width: 70%;
        min-width: 180px;
        max-width: 300px; 
    }   
}