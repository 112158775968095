
@import '../constants/Colors.scss';

.BodyTextExExSmall{
    font-size:0.55rem; 
    color:$contentFontColor
}

.BodyTextExSmall{
    font-size:0.65rem; 
    color:$contentFontColor
}

.BodyTextSmall{
    font-size:0.75rem; 
    color:$contentFontColor
}

.BodyText{
    font-size:0.8rem;
    color:$contentFontColor    
}

.BodyMediumText{
    font-size:1rem;
    color:$contentFontColor
}

.BodyMediumLText{
    font-size: 1.15rem;
    font-weight:bold;
    color:$contentFontColor
}

.BodyLargeText{
    font-size:1.3rem;
    font-weight:bold; 
    color:$contentFontColor
}

.BodyMidiumLargeText{
    font-size:1.5rem;
    font-weight:bold; 
    color:$contentFontColor
}

.BodyExLargeText{
    font-size:1.7rem;
    font-weight:bold; 
    color:$contentFontColor
}

.Bold{
    font-weight:bold
}

.BorderBottom {
    border-bottom: 1px solid $contentFontTintColor;
} 

.BorderTop {
    border-top: 1px solid $contentFontTintColor;
} 
