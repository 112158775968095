.ArticleHead {
    display:flex; 
    justify-content:flex-start; 
    align-items:center;
    margin-bottom: 10px;
}

.ArticleTitle {
    margin-left:8px;
    font-size: 1.1em;
    font-weight: bold;
}    

.ArticleDesc {
    font-size: 0.9em;  
}

.HeaderMargin20{
    margin-top:20px;
}

.HeaderMargin15 {
    margin-top:15px;
}

.HeaderMargin10 {
    margin-top:10px;
}

.ImageWide {
    width: 90%;
    padding: 10px 0;
}

.ScreenShot {
    width: 90%;
    max-width:250px;
}

.ScreenShotRight {
    padding-left:0px;
}

.MultiImageBox{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  width:100%;
  align-items:flex-start;
}

.FeeTitle {
    font-size: 1em;
    font-weight: bold;
}

.FeeMiddle {
    display:flex; 
    align-items:center;
}

.FeeContent {
    font-size: 1.2em;
    font-weight: bold;
}

.Icon {
  font-size: 1em;
  color: #3f3e3e;
}

.HowToStep li {
    margin:20px 0px 15px 0;
}

.Red {
    color: #ed503c;
}
.Blue {
    color: #44a7cb;
}

.WaringNote {
    font-size: 0.75em;
    color: #ed503c; 
}

@media (min-width: 600px) {
    .ImageWide {
        max-width: 480px;
    }
    .ScreenShot {
        width: 90%;
        max-width:270px;    
    }
}

@media (min-width: 900px) {
    .ScreenShotRight {
        margin-left:10px;
    }
}

