@import "../../constants/Colors.scss";

.ReviewItemsContainer {
    width: 90%;
    min-width: 300px;
    max-width: 300px;
    overflow-y: auto;
    // height:90%;
    max-height: 90%;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    background-color: $contentBGColor;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 400;
}

.CloseBtnContainer{
    width:100%;
    display:flex;
    flex-direction:row; 
    height:30px; 
    align-items:center; 
    justify-content:flex-end;
    padding:10px 5px;
}

.Open {
    display: block;
}

.Close {
    display: none;
}