@import '../../../constants/Colors.scss';

.PodSmallRegular {
  width: 100%;
  height: 150px;
  background-color: #F0F3E5;
  min-width: 320px;
  //max-width: 355px;
  margin-top:20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;
  text-decoration: none;
}

.PodSmallRegular:hover {
  opacity: 0.5;
}

.PodImageSmallRegular {
  width:150px;
  height:150px;
}

.PodImgSmallRegular {
  width: 100%;
  height: 100%;
}

.PodSmallTextAreaRegular {
  padding: 10px;
  flex:1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 3em;
}

.PodSmallTextHeadRegular {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $contentFontColor;
}

.PodSmallTextRegular {
  font-size: 12px;
  line-height: 22px;
  white-space: pre-wrap;
  color: $contentFontColor;
}

.Details {
  color: $primaryButtonBGColor;
}

@media (min-width: 375px) {

}

@media (min-width: 530px) {

  .PodSmallRegular {
    height: 220px;
  }

  .PodImageSmallRegular {
    width:220px;
    height:220px;
  }

  .PodSmallTextHeadRegular {
    font-size: 20px;
  }
  
  .PodSmallTextRegular {
    font-size: 16px;
    line-height: 27px;
  }
  
}

@media (min-width: 768px) {
  
  .PodImageSmallRegular {
    width: 100%;
    height: auto;
  }

  .PodSmallRegular {
    min-width: 100%;
    max-width: 100%;
    height: auto;
    flex-direction: column;  
  }

  .PodSmallTextAreaRegular {
    flex:1;
    width: 100%;
    min-height: 180px;
  }

}

@media (min-width: 1024px) {
  /* Hero Area */
  .PodSmallTextAreaRegular {
    padding: 15px;
    min-height: 160px;
  }

  .PodSmallTextHeadRegular {
    font-size: 18px;
    margin-bottom: px;
  }
  
  .PodSmallTextRegular {
    font-size: 16px;
    line-height: 30px;
  }
}

@media (min-width: 1300px) {
  /* pad small */
}

@media (min-width: 1500px) {
  .PodSmallTextAreaRegular {
    padding: 20px;
  }

  .PodSmallTextHeadRegular {
    font-size: 24px;
  }
  
  .PodSmallTextRegular {
    font-size: 20px;
    line-height: 32px;
  }
}