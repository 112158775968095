.Footer {
    width: 100%;
    padding: 5px 5px 15px 15px;
    background-color: #b0b5bb;
}

.Item a, .SnsSection a {
    text-decoration: none;
    font-size: 0.75rem;
    line-height: 1.35rem;
    color: rgb(63, 62, 62); 
}

.Footer h5 {
    margin-top: 15px;
    font-size: 0.85rem;
    color: rgb(63, 62, 62); 
}

.Item a:hover 
.Item a:active
.Item a.active
{
    color: rgb(245, 236, 236);
}

.FooterContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.Section img {
    margin: 10px 0 0 0;
    width: 60%;
    max-width: 150px;
}

.Section {
    flex:1;
    min-width: 150px;
    padding:0px;
    text-align: left;
}

.CopyRight {
    padding: 15px 0 0 0;
    font-size: 0.7rem; 
    color: rgb(63, 62, 62);
    text-align: center;    
}

.Icon {
  font-size:1.525rem;
}

.SnsSection {
  margin-top: 10px;
  flex:1;
  min-width: 150px;
  padding:0px;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.space{
  display: block;
}

@media print {
  .Footer {
    display:none
  }    
}

@media (min-width: 800px) {
  .space {
    display: none;
  }  
}