@import '../../constants/Colors.scss';

.ItemContainer{
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 6px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.26);
    border: none;
    border-radius:5px;
    width: 150px; 
    height: 300px;
    background-color: $white;
    transition: 0.3s;
}

.ItemContainer:hover {
    transform: scale(1.1)
}

.GigImageContainer{
    width: 100%; 
    height: 150px;
    border-radius:5px 5px 0 0;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.SubCatHeader{
    background-color: #ffffff6e;
    border-radius:5px 5px 0 0; 
    justify-content:center;
    align-Items:center;
    width: 100%; 
    height:25px;
    display: flex; 
    flex-direction:row;
    font-weight: bold;
}

.AubCatText{
    color:$contentFontColor;
    font-size: 15px;
    font-weight: bold;
}

.FavIconContainer{ 
    position:absolute; 
    right:10px;
}

.MainCatIconContainer{ 
    opacity: 0.75; 
    position:absolute;
    display: flex; 
    justify-content:center; 
    align-Items:center; 
    right:10px; 
    top:85px; 
    width:30px; 
    height:30px;
} 

.CatIconHELP {
    font-size:16px;
    color:$white;
    padding-right:7px;
}

.CatIconTEACH {
    font-size:16px;
    color:$white;
    padding-bottom:3px;
}

.CatIconRENT {
    font-size:16px;
    color:$white;
    padding-right:4px;
    padding-bottom:1px;
}

.CatIconMAKE {
    font-size:16px;
    color:$white;
    padding-right:3px;
    padding-bottom:1px;
}

.MainCatIconCircle{
    display: flex; 
    flex-direction: row;
    justify-content:center; 
    align-Items:center;
    border-radius:15px;
    width:30px;
    height:30px; 
}

.PriceTagSvgContainer{
    width:100%;
    height:25px;
    opacity:0.8;
    position:absolute;
    top:125px;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-Items:flex-end
}

.PriceTagContainer{
    width:100%;
    height:25px;
    position:absolute;
    top:125px;
    display: flex;
    justify-content:flex-end;
    align-Items:center;
    flex-direction:row
}

.PriceText{ 
    display: flex; 
    justify-content:center; 
    align-Items:center; 
    color: $mainMenuTextColor;
    font-size: 13px;
    font-weight:bold;
    padding-right:5px;
}

.MaxPerson{ 
    color: $mainMenuTextColor;
    font-size: 11px;
    font-weight:normal
}

.IndiCatorContainer{
    display: flex;
    flex-direction:row;
    background-color:$white;
    border-radius:3px;
    align-Items:center;
    justify-content:center;
    padding:2px;
    margin-left:5px;
    margin-right:5px;
}

.IndicatorText{
    color: $redColor;
    font-size:10px;
    font-weight:bold
}

.GigTitleContainer{
    margin-top: 10px;
    margin-bottom:10px;
    margin-right:5px;
    margin-left:7px;
}

.GigTitleInnerContainer{
    height:40px; 
    width:100%; 
    display: flex;
    flex-direction: row;
    align-Items: center;
}

.GigTitleText{
    color:$contentFontColor;
    font-size: 12px;
    font-weight: bold;
    line-height:19px;
    // width: 0px;
    flex: 1px;
}

.UserInfoContainer{
    display: flex;
    flex-direction:row;
    justify-content:center;
    width:100%;
    height:60px;
    border-bottom:1px solid $contentFontTintColor;
    border-top:1px solid $contentFontTintColor;
}

.AvatorContainer{
    width:60px; 
    display: flex;
    flex-direction: column;
    justify-content:center; 
    align-Items:center;
    border-right:1px solid $contentFontTintColor; 
}

.AddressLineContainer{
    flex:1;
    padding-top: 5px;
    display: flex;
    justify-content:center;
    align-Items:center;
    flex-direction:row;
}

.AddressText{
    color:$contentFontColor;
    font-size:11px;
    margin-left:5px;
}

@media (min-width: 375px) {
    .ItemContainer {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    }    
}

@media (min-width: 500px) {
    .ItemContainer {
        width: 170px;
        margin-right: 10px;
    }   
}

@media (min-width: 1024px) {
    .ItemContainer {
        width: 170px;
        margin-right: 20px;
        margin-bottom: 40px; 
    }    
}