.TabContainer {
    width: 100%;
    margin: 0px auto;
    display: flex;
}

.TabLabel {
    position: relative;
    font-size: 1.05rem;
    font-weight: bold;
    color: #41BEEB;
    border-bottom: 3px solid #41BEEB;
    border-top: 1px solid #41BEEB;
    border-left: 1px solid #41BEEB;
    border-right: 1px solid #41BEEB;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 155px;
    height: 54px;
    padding: 10px;
    text-align: center;
    transition: background-color 0.6s ease;
    transition: color 0.6s ease;
}

.Active, .TabLabel:hover{
    background-color: #41BEEB;
    color: white;
    border: none;
}

.TabIndicator {
    width: 20px;
    height: 10px;
    bottom: 0px;
    margin-left: -10px;
    position: absolute;
}

.TabGap {
    width: 10px;
    border-bottom: 3px solid #41BEEB;
}

.TabGapRemain {
    flex:1;
    border-bottom: 3px solid #41BEEB;
}

.MeritArea {
    width: 100%;
    margin: 30px auto 0px auto;
    display: flex;
    flex-flow: column;
    color: rgb(63, 62, 62);
    justify-content:center;
    align-items: center;
    margin: 15px auto 0 auto;
}

.MeritContainer{
    flex:1;
    width: 280px;
    text-align: center;
    padding: 15px;
    margin: 0 0 30px 0;
    background-color: #ffffff;
    border-radius: 0px;
    box-shadow: 1px 1px 5px #88888871;
}

.MeritContainer h5{
    font-size: 0.98rem;
    padding-bottom: 10px;
}

.MeritContainer p{
    font-size: 0.9rem;
    padding: 10px 0 0 0;
    text-align: left;
}

.MeritImage {
    width: 80%;
    border-radius:50%;
}

@media (min-width: 321px) {
    .TabContainer {
        width: 95%;
        min-width: 320px;
    }
    .MeritContainer{
        border-radius: 15px;  
    }    
        
}

@media (min-width: 860px) {
    .TabContainer, .MeritArea {
        max-width: 95%;
    }
    .TabGap {
        width: 20px;
    } 
    .MeritArea {
        flex-flow: row;
        justify-content:space-between;
        align-items: flex-start;
    }
    .MeritContainer{
        max-width: 260px;
        padding: 8px;
    }
}

@media (min-width: 1024px) { 
    .TabContainer, .MeritArea {
        width: 90%;
        max-width: 1200px;
    }
    .TabLabel {
        width: 250px;
        height: 60px;
        padding: 15px;
    } 
    .MeritContainer{
        max-width: 280px;
        padding: 15px;
    }    
}

@media (min-width: 1500px) {
    .MeritContainer{
        max-width: 320px;
        padding: 20px;
    }  
}