.HelpMenu {
    min-width: 250px;
    flex:1;
    margin-right:10px;
}

.HelpMenu ul {
    list-style: none;
    background-color: #fff;
    color: #707070;
    font-size: 0.85rem;
    cursor: pointer;
    width: 100%;
    border: none;
    margin-bottom:15px;
}

.SecTile {
    background-color: #D0E9F6;
    border-radius: 5px 5px 0 0;
    color: #707070;
    font-size: 0.90rem;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
    transition: 0.4s;
    border: 1px solid #D6D6D6;
    border-bottom: none;
}

.Accordion, .AccordionTitle {
    background-color: #EBEBEB;
    color: #707070;
    font-size: 0.85rem;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
    transition: 0.4s;
    border: 1px solid #D6D6D6;
    border-bottom: none;
    transition: 0.4s;    
}

.Accordion:after, .SubAccordion:after {
    content: '\f0d7'; /* fontawesome caret-down sign */
    font-family: "FontAwesome";
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px;
}

.Active:after, .SubActive:after {
    content: "\f0d8"; /* fontawesome caret-up sign */
}

.SubAccordion:after {
    margin-left: 0px;
}

ul.Panel {
    border-radius:0;
    margin-bottom:0;
    max-height: 0;
    overflow:hidden;
    transition: max-height 0.2s ease-out;
}

ul.Panel > li  {
    padding: 8px 20px;
    font-size: 0.8rem;
    border: 1px solid #D6D6D6;
    border-bottom: none;  
}

div.SubPanel {
    max-height: 0;
    overflow:hidden;
    transition: max-height 0.2s ease-out; 
}

div.SubPanel > li  {
    padding: 8px 30px;
    font-size: 0.75rem;
    border: 1px solid #D6D6D6;
    border-bottom: none; 
}

.HelpMenu li:hover, .MenuActive  {
    cursor: pointer;
    color: #41BEEB;
    background-color: #faf8f8;
}

.Active, .SubActive {
    color: #41BEEB;
}

.HelpMenu > ul > li {
    padding: 8px 10px;
    text-align: left;
    border: 1px solid #D6D6D6;
    border-bottom: none;
}

.HelpMenu > ul > li.Last {
    border-bottom: 1px solid #D6D6D6;
    border-radius: 0 0 5px 5px;
}
