@import '../../../constants/Colors.scss';

.PodSmall {
  width: 100%;
  height: 150px;
  background-color: #F0F3E5;
  min-width: 320px;
  margin-top:20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;
  text-decoration: none;
}

.PodSmall:hover{
  opacity: 0.5;
}

.PodImageSmall {
  width:150px;
  height:150px;
}

.PodImgSmall {
  width: 100%;
  height: 100%;
}

.PodSmallTextArea {
  padding: 10px;
  flex:1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 3em;
}

.PodSmallTextHead {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $contentFontColor;
}

.PodSmallText {
  font-size: 12px;
  line-height: 22px;
  white-space: pre-wrap;
  color: $contentFontColor;
}

.Details {
  color: $primaryButtonBGColor;
}

@media (min-width: 375px) {

}

@media (min-width: 530px) {
  .PodSmall {
    height: 220px;
  }

  .PodImageSmall {
    width:220px;
    height:220px;
  }

  .PodSmallTextHead {
    font-size: 20px;
  }
  
  .PodSmallText {
    font-size: 16px;
    line-height: 27px;
  }
}

@media (min-width: 768px) {
  /* big pod */
  .PodImageSmall {
    width: 100%;
    height: auto;
  }

  /* pod small */
  .PodSmall {
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
    margin-top:0px;
  }
  
  .PodSmallTextArea {
    display: none;
  }
  /* small pod */
}

@media (min-width: 1024px) {
  /* pad small */
  .PodSmall {
    min-width: 220px;
    max-width: 220px;
    min-height: 220px;
    max-height: 220px;
    margin-top:0px;
  }

  .PodSmallTextHead {
    font-size: 18px;
    margin-bottom: px;
  }
  
  .PodSmallText {
    font-size: 16px;
    line-height: 30px;
  }
  /* pod small */
}

@media (min-width: 1300px) {
  /* pad small */
  .PodSmall {
    min-width: 280px;
    max-width: 280px;
    min-height: 280px;
    max-height: 280px;
    margin-top:0px;
  }
}

@media (min-width: 1500px) {
  /* pad small */
  .PodSmall {
    min-width: 300px;
    max-width: 300px;
    min-height: 300px;
    max-height: 300px;
    margin-top:0px;
  }
  
  .PodSmallTextHead {
    font-size: 24px;
  }
  
  .PodSmallText {
    font-size: 20px;
    line-height: 32px;
  }
}