@import '../../../constants/Colors.scss';

.GigImage {
    background-color: $contentFontTintColor;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
	margin: 0;
    padding: 0;
    display: none;
}

.Prev, .Next {
    cursor: pointer;
    position: absolute;
    text-decoration: none;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: $white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    border:none;
    user-select: none;
}
  
.Next {
    right: 0;
    border-radius: 3px 0 0 3px;
}
   
.Prev:hover, .Next:hover {
    background-color: $dropDownShadow;
}

.CircleBox {
    width:100%;
    position:absolute;
    bottom: 20px;
    height:20px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-Items:center;
}

.WhiteCircle {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 3px;
    background-color: $white;
    border-radius: 50%;
    display: inline-block;
    border:none;
    transition: opacity 0.6s ease;
}

@media (min-width: 375px) {
    .GigImage{
        border-radius: 5px;
    }     
}

@media (min-width: 766px) {
    .WhiteCircle {
        height: 12px;
        width: 12px;
        margin: 0 5px;
    }
    .CircleBox {
        height:22px;
    }    
}