.CallToAction{
    margin: 15px auto;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    justify-content:center;
}

.Icon {
    font-size:17px;
}

.AreaInfoContainer { 
    width:50%;
    display:flex;
    align-items:flex-end;
    flex-direction:row
}

.ServiceLocContainer{
    display: flex;
    flex-direction:column;
    width:50%;
    justify-content:space-evenly;
    align-items:flex-start;
}

.CoveredAreaContainer{
    display: flex;
    flex-direction:row;
    width:40%;
    justify-content:flex-start;
    align-items:flex-end;
}

.ButtonRowContainer { 
    display: flex;
    flex-direction: row; 
    justify-content:space-between;
    width: 100%; 
    height: 40px; 
    margin-bottom:15px; 
    padding-left:15px; 
    padding-right:15px;
}