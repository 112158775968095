@import '../../constants/Colors.scss';

.AboutHeader {
  position: relative;
  background-color: $white;
  margin: 0;
  padding: 0;
}

.HeroImage {
  background-image: url(../../assets/images/About.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 215px;
  width: 100%;
  min-width: 320px;
  margin: 0;
  padding: 0;
}

.LogoWithTypeFace {
  display:flex;
  justify-content: flex-start;
  align-items: center;
}

.Logo {
  width: 37px;
}

.LogoTypeFace {
  margin-left:3px;
  width: 116px;    
}

.HeroText {
  width:305px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TagLine {
  font-size:16px;
  color: #ffffff;
  text-shadow: 2px 2px 3px #444444;
  margin-bottom: 30px;
}

.BrandBox {
  margin-bottom: 5px;
  color: #ffffff;
  text-shadow: 2px 2px 3px #444444;    
}

.SectionHead {
  display: block;
  min-width: 300px;
  margin:0 0 20px 0; 
  background-color: #fff;
  text-align:center;
}

.AboutPageContent {
  width: 100%;
  background-color: $white;
  margin: 0;
  padding:20px 0;
}

.Banner {
  width: 100%;
  height: 100px;
  background-image: url(../../assets/images/about_banner.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.BannerText {
  font-size: 18px;
  font-weight: bold;
  display: block;
  color: $white;
  text-shadow: 2px 2px 3px #444444;
}

@media (min-width: 530px) {
  .HeroImage {
    height: 290px;
  }

  .HeroText{
    width: 480px;
  }

  .BrandBox {
    margin-bottom: 5px;    
  }

  .Logo {
    width: 45px;
  }
  
  .LogoTypeFace {
    margin-left:3px;
    width: 162px;    
  }

  .TagLine {
    font-size:23px;
    margin-bottom: 30px;
  }

  .SectionHead {
    font-size: 1.25rem;
  }
  .Banner {
    height: 160px;
  }
  .BannerText{
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .HeroImage {
    height: 290px;
  }

  .HeroText{
    width: 480px;
  }
  .BrandBox {
    margin-bottom: 5px;    
  }

  .Logo {
    width: 45px;
  }
  
  .LogoTypeFace {
    margin-left:3px;
    width: 162px;    
  }

  .TagLine {
    font-size:23px;
    margin-bottom: 30px;
  }

  .SectionHead {
    font-size: 1.5rem;
    margin:0 0 30px 0; 
  }
  .BannerText{
    font-size: 24px;
  }
}

@media (min-width: 1024px) {
  .HeroImage {
    height: 480px;
  }

  .HeroText{
      width: 720px;
  }

  .BrandBox {
    margin-bottom: 20px;    
  }

  .Logo {
    width: 67px;
  }

  .LogoTypeFace {
    margin-left:10px;
    width: 248px;    
  }

  .TagLine {
    font-size:35px;
    margin-bottom: 30px;
  }

  .Banner {
    height: 180px;
  }
  .BannerText{
    font-size: 28px;
  }

}

@media (min-width: 1500px) {
  .HeroText{
    width: 800px;
  }

  .SectionHead {
    font-size: 1.65rem;
    margin:0 0 40px 0; 
  }
  .Banner {
    height: 200px;
  }
  .BannerText{
    font-size: 35px;
  }  
}