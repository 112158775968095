@import '../../../constants/Colors.scss';

.Certificates {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    padding-top:2px
}

.BudgeContainer {
    flex:1;
    height: 70px;
    display: flex;
    flex-direction: column;
    // justify-content:space-evenly; 
    align-items:center;
    text-align: center;
}

.LabelText {
    color: $contentFontColor;
    font-size: 10px
}