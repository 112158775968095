@import '../../../constants/Colors.scss';

.ToastModal {
    position: fixed;
    width: 280px;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:300;
    padding: 15px;
    border-radius: 15px;
    background-color: #000000AA;
    transition: all 0.4s ease-out;
    color: $primaryButtonTextColor
}

.Open {
    top: 40%;
}

.Close {
    top: -50%;
}

.Icon{
    display: block;
    font-size:2rem;
    margin-right: 2rem;
    color:$green;
}

@media print {
    .ToastModal {display:none}    
}