@import '../../constants/Colors.scss';

.SubCatButton {
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction:row;
    height:30px;
    margin:5px;
    margin-right: 10px;
    border:1px solid $primaryButtonBGColor;
    border-radius: 17.5px;
    background-color: $white;
    color: $primaryButtonBGColor;
    position: relative;
}

.SubCatButton:hover  {
    color: $white;
    background-color: $primaryButtonBGColor;  
}

.SubCatSelected {
    color: $white;
    background-color: $primaryButtonBGColor;  
}

.ButtonText {
    padding: 0 0.5rem;
    font-size: 0.75rem;
    font-weight: bold
}

@media (min-width: 1024px) {
  .SubCatButton {
    height:40px;
    margin:8px;
    margin-right: 12px;
    border-radius: 20px;
  }

  .ButtonText {
    padding: 0 0.7rem;
    font-size: 0.85rem;
  }
  
}