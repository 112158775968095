.FeaturedListContainer {
    min-width: 320px;
    width:100%;
    padding:8px;
    overflow:hidden;
}

.SectionContainer {
    width: 100%;
    min-width: 320px;
}

.BottomRow {
    margin-bottom: 50px;
}

.SectionHead {
    width: 100%;
    min-width: 320px;
    margin-top:50px; 
    border-top: 1px solid rgba(63, 62, 62, 0.2);
    background-color: #fff;
    text-align:center;  
    padding: 15px 0 15px 0;
}

.HomeContent{
  background-color: #f5f5f5;
}

@media (min-width:375px){
    .FeaturedListContainer{
        padding:0;
        width:90%;
        max-width: 950px;
        margin:0 auto;   
    }    
}
