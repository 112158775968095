@import "../../../constants/Colors.scss";

.DropZone {
  border:5px dashed $tintColorBGColor;
  text-align:center;
  border-radius:5px;
  width:200px;
  height:200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding:10px;
}

.DropZonePlaceHolder {
  font-size: 4em;
  color: $tintColorBGColor;
}

.DropZoneText, .DropZoneError {
  margin: 5px auto;
  width:98%;
  color: $tintColorBGColor;
  font-weight:bold;
  font-size:0.7rem;
  text-decoration: none;
}
.DropZoneError{
  color: $redColor
}