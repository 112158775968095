@import '../../../constants/Colors.scss';

.PodSmallRegularLayout {
  width: 100%;
  display: flex;
  flex-direction: column;    
}

.PodSmallRegularBox {
  width: 100%;
}

@media (min-width: 375px) {

}

@media (min-width: 530px) {
  
}

@media (min-width: 768px) {
  /* small regular pod */
  .PodSmallRegularLayout {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;  
  }
  
  .PodSmallRegularBox {
    width: 30%;
    margin-bottom: 1em;
  }
}

@media (min-width: 1024px) {
}

@media (min-width: 1500px) {
}