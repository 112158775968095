@import "../../../constants/Colors.scss"; 

.Label {
    width: 1.3em;
    height: 1.3em;
    border-radius: 5px;
    border:1px solid $contentFontColor;
    font-size:1.2em;
    font-weight:bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $contentFontColor
}

.CenterLabel {
    font-size:0.95em;
    font-weight:bold;    
}
