@import '../../../constants/Colors.scss';

.FeatureLayout {
  width: 100%;
  display: flex;
  flex-direction: column;  
}

.FeatureLeft {
  width: 100%;
}

.FeatureRight {
  width: 100%;
}

.FeatureRightBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.FeatureBottom {
  align-items: flex-end;  
}

.PodSmallRegularLayout {
  width: 100%;
  display: flex;
  flex-direction: column;    
}

.PodSmallRegularBox {
  width: 100%;
}

@media (min-width: 375px) {

}

@media (min-width: 530px) {
  
}

@media (min-width: 768px) {
  /* FeatureLayout */
  .FeatureLayout {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;  
  }
  
  .FeatureLeft {
    width: 40%;
    //background-color: red;
  }
  
  .FeatureRight {
    width: 60%;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
  }

  .FeatureRightBox {
    width: 50%;
    justify-content: flex-end;
  }
  
  .FeatureBottom {
    align-items: flex-end;  
  }

  /* FeatureLayout */
  
  /* small regular pod */
  .PodSmallRegularLayout {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
  }
  
  .PodSmallRegularBox {
    width: 30%;
  }
}

@media (min-width: 1024px) {
  .Banner {
    height: 180px;
  }
  .BannerText{
    font-size: 28px;
  }
}

@media (min-width: 1500px) {
}
