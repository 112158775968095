@import '../../../constants/Colors.scss';

.TagSvg {
    background-position:left top;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: row;
    background-image: url(../../../assets/images/tag_inactive_65x50.svg);
}

.HELP {
    background-image: url(../../../assets/images/tag_help_65x50.svg);
}

.TEACH {
    background-image: url(../../../assets/images/tag_teach_65x50.svg);
}

.RENT {
    background-image: url(../../../assets/images/tag_rent_65x50.svg);
}

.MAKE {
    background-image: url(../../../assets/images/tag_make_65x50.svg);
}