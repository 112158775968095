@import '../../../constants/Colors.scss';

.PodSmallVideo {
  width: 100%;
  background-color: #F0F3E5;
  min-width: 320px;
  margin-top:20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  text-decoration: none;
}  

.PodImageSmallVideo {
  width:100%;
  height:auto; 
}

.PodImgSmallRegular {
  width: 100%;
  height: 100%;
}

.PodSmallTextAreaVideo {
  padding: 10px;
  flex:1;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PodSmallTextHeadVideo {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $contentFontColor;
}

.PodSmallTextVideo {
  font-size: 12px;
  line-height: 22px;
  white-space: pre-wrap;
  color: $contentFontColor;
}

.Details {
  color: $primaryButtonBGColor;
}

@media (min-width: 375px) {
}

@media (min-width: 530px) {
  
  .PodSmallTextHeadVideo {
    font-size: 20px;
  }
  .PodSmallTextVideo {
    font-size: 16px;
    line-height: 27px;
  }
}

@media (min-width: 768px) {
  .PodSmallVideo {
    min-width: 100%;
    max-width: 100%;
    height: auto;
    flex-direction: column;  
  }

  .PodSmallTextAreaVideo {
    min-height: 180px;
  }
}

@media (min-width: 1024px) {

  .PodSmallTextAreaVideo {
    min-height: 160px;
  }

  .PodSmallTextHeadVideo {
    font-size: 18px;
    margin-bottom: px;
  }
  
  .PodSmallTextVideo {
    font-size: 16px;
    line-height: 30px;
  }
  
}

@media (min-width: 1300px) {
}

@media (min-width: 1500px) {
  
  .PodSmallTextHeadVideo {
    font-size: 24px;
  }
  
  .PodSmallTextVideo {
    font-size: 20px;
    line-height: 32px;
  }
}