@import '../../../constants/Colors.scss';

.Cotent{
  position:relative;
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding:'5px';
  background-image: url(../../../assets/images/white_circle.svg);
  background-position:center 75px;
  background-repeat: no-repeat;
  background-size: cover;
}

.AvatorBox {
  display:flex;
  flex-direction:row;
  margin-top:0;
  width:112px;
  height:112px;
  justify-content:center;
  align-items:center;
  border-radius:56px;
  background-color:$white;
  padding:0 8px;
}

.UserNameBox {
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  margin:8px 0;
  padding:0 8px;
}

.Details {
  width: 100%;
  padding:0 8px;
}

.ContentRow{
  display: flex; 
  flex-direction:row; 
  width:100%; 
  justify-content:center; 
  align-items:center; 
  margin:10px 0; 
  padding:10px 0;
}