@import '../../../constants/Colors.scss';

.Greeting {
  width: 90%;
  min-width: 300px;
  margin: 20px auto;
}

.GreetingTitle {
  margin-bottom: 10px;
  text-align: center; 
}

.GreetingText {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0.8em;
}

.CEOImageBox {
  width: 40%;
  height: auto;
  float: left;
  margin-right: 10px;
  margin-bottom: 0px;
}

.CEOImage {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

.Section {
  width: 90%;
  min-width: 300px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #FFEDBF;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

.SectionTitleBG{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  transform: rotate(15deg);
  position: absolute;
}

.TagBG {
  top: 35%;
  left: 25%;
  background-image: url(../../../assets/images/tag_yellow60x50.svg);
}

.HeartBG {
  top: 35%;
  left: -25%;
  background-image: url(../../../assets/images/heart_yellow.svg);
  transform: rotate(25deg);
}

.FlowerBG {
  width: 200%;
  height: 200%;
  top: 20%;
  left: 25%;
  transform: rotate(25deg);
  background-image: url(../../../assets/images/flower_yellow.svg);
}

.CloverBG {
  top: 40%;
  left: -30%;
  background-image: url(../../../assets/images/clover_yellow.svg);
}

.SectionContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.ResumeArea {
  width: 100%;
  text-align: left;
  position: relative; 
  padding-right: 90px; 
}

.PaddingLeft {
  padding-right:0;
  padding-left: 90px;
}

.LeftBox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px; 
}

.ResumeRow{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;  
}

.ResumeTitleArea{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin:20px 0 10px 0;
}

.ResumeTitle {
  font-size: 12px;
  font-weight: bold;
  color: #412F0B;
}

.ResumeBorder{
  width: 120px;
  margin: 7px 0;
}

.ResumeTextArea{
  list-style: none;
  padding: 0 10px 0 10px;
}

.ResumeTextArea li {
  font-size: 10px;
  line-height: 1.6rem;
}

.PortrayBox {
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 15;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} 

.PortrayBoxLeft {
  right: auto;
  left: 8px;  
}

.Portray {
  border: 6px solid $white;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

.RoleBox {
  margin-top: 8px;
  width: 80px;
  height: 40px;
  background-image: url(../../../assets/images/tag_head_left.svg);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: auto 40px;
}

.Role {
  border-radius: 0 6px 6px 0;
  width: 65px;
  height: 40px;
  display: block;
  background-color: #F5B718;
  padding: 0 3px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.RoleTitle {
  font-size: 8px;
  font-weight: bold;
}

.Name{
  font-size: 10px;
  font-weight: bold;
}

.FavoriteArea{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.FavoriteAreaLeft {
  padding-left: 0px;
}

.FavoriteTitle {
  font-size: 14px;
  font-weight: bold;
  color: #412F0B;
}

.FavoriteBorder{
  width: 125px;
  margin: 5px 0;
}

.Statement {
  font-size: 12px;
  font-weight: bold;
  color: #412F0B;
  text-align: center;
  padding:3px 15px;
  background-image: url(../../../assets/images/quotes_start.svg), url(../../../assets/images/quotes_end.svg);
  background-position: left top, right bottom;
  background-repeat: no-repeat;
  background-size: 40px auto;
}

.Description {
  width: 95%;
  font-size: 14px;
  line-height: 1.85em;
  color: #412F0B;
  margin: 20px auto;
  position: absolute;
}

@media (min-width: 375px) {
  .SectionContent {
    max-width: 380px;
  }
}

@media (min-width: 530px) {
  .Section {
    margin: 60px auto;
  }
  .ResumeTextArea li {
    font-size: 12px;
    line-height: 2rem;
  }
  .PaddingLeft{
    padding-right: 0; 
    padding-left: 120px;
  }
  .PortrayBox {
    right: 30px;
    top: 30px;
  }
  .PortrayBoxLeft {
    right: auto;
    left: -30px; 
  }
  .Portray {
    border: 10px solid $white;
    width: 120px;
    height: 120px;
  } 
}

@media (min-width: 768px) {
  
  .GreetingTitle {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .GreetingText {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 1em;
  }
  
  .CEOImageBox {
    width: 30%;
    margin-right: 20px;
  }
  
  .CEOImage {
    border-radius: 8px;
  }

  .SectionContent {
    max-width: 500px;
  }
  .ResumeArea {
    padding-right: 225px; 
  }

  .PaddingLeft{
    padding-right: 0; 
    padding-left: 235px;
  }

  .PortrayBox {
    right: 15px;
    top: 15px;
  }

  .PortrayBoxLeft{
    right: auto;
    left: 15px;
    top: 15px;
  }
  .Portray {
    border: 10px solid $white;
    width: 200px;
    height: 200px;
  }
  .RoleBox {
    margin-top: 20px;
    width: 125px;
    height: 60px;
    background-size: auto 60px;
  }
  
  .Role {
    border-radius: 0 10px 10px 0;
    width: 105px;
    height: 60px;
    padding: 0 12px;
    margin-left: 20px;
  }
  
  .RoleTitle {
    font-size: 10px;
    font-weight: bold;
  }
  
  .Name{
    font-size: 14px;
    font-weight: bold;
  }
  .FavoriteArea{
    margin-bottom: 30px;
  }
  
  .FavoriteTitle {
    font-size: 16px;
    font-weight: bold;
    color: #412F0B;
  }
  
  .FavoriteBorder{
    width: 150px;
    margin: 8px 0;
  }
  
  .Statement {
    font-size: 14px;
    line-height: 1.8rem;
    padding:3px 20px;
    background-size: 60px auto;
  }
  
}

@media (min-width: 1024px) {

  .Greeting {
    max-width: 1000px;
  }

  .GreetingTitle {
    font-size: 1.25rem;
    margin-bottom: 20px;
  }
  
  .GreetingText {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  
  .CEOImageBox {
    width: 30%;
    margin-right: 30px;
    margin-bottom: 20px;
  }
  
  .CEOImage {
    border-radius: 10px;
  }

  .Section {
    max-width: 1000px;
  }

  .SectionContent {
    max-width: 860px;
  }

  .ResumeArea {
    padding-right: 265px; 
  }

  .PaddingLeft {
    padding-right: 0px;
    padding-left : 265px;
  }

  .PortrayBox {
    right: 15px;
    top: 25%;
  }

  .PortrayBoxLeft{
    right: auto;
    left: 15x;
    top: 20%;  
  }

  .Portray {
    width: 250px;
    height: 250px;
  }

  .RoleBox {
    margin-top: 30px;
    width: 180px;
    height: 80px;
    background-size: auto 80px;
  }
  
  .Role {
    border-radius: 0 10px 10px 0;
    width: 165px;
    height: 80px;
    padding: 0 30px;
    margin-left: 30px;
  }
  
  .RoleTitle {
    font-size: 12px;
    font-weight: bold;
  }
  
  .Name{
    font-size: 18px;
    font-weight: bold;
  }


  .ResumeTitleArea{
    align-items: center;
    margin:20px 0 10px 0;
  }

  .ResumeTitle {
    font-size: 16px;
  }
  
  .ResumeBorder{
    width: 150px;
    margin: 8px 0;
  }

  .ResumeRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .ResumeTextArea {
    flex:1;
  }

  .ResumeTextArea li{
    font-size: 14px;
  }

  .FavoriteArea{
    margin-top: 20px;
    padding-right: 270px;
  }

  .FavoriteAreaLeft{
    padding-right: 0px;
    padding-left : 265px;
  }
  
  .FavoriteTitle {
    font-size: 16px;
  }
  
  .FavoriteBorder{
    width: 150px;
    margin: 8px 0;
  }

  .Statement {
    font-size: 18px;
    line-height: 1.8em;
  }

}

@media (min-width: 1500px) {

  .Greeting {
    max-width: 1200px;
  }
  
  .GreetingText {
    font-size: 18px;
    line-height: 32px;
  }

  .Section {
    max-width: 1200px;
  }

  .SectionContent {
    max-width: 1000px;
  }
  .ResumeTextArea li{
    font-size: 16px;
  }
}