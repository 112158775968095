@import '../../../constants/Colors.scss';

.Section {
  width: 90%;
  min-width: 300px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SectionTitleBox{
  width: 100%;
  height: 150px;
  background-color: #FFEDBF;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

.SectionTitleBG{
  width: 150%;
  height: 150%;
  background-repeat: no-repeat;
  transform: rotate(15deg);
  position: absolute;
}

.TagBG {
  top: 20%;
  left: 25%;
  background-image: url(../../../assets/images/tag_yellow60x50.svg);
}

.HeartBG {
  top: 80%;
  left: -25%;
  background-image: url(../../../assets/images/heart_yellow.svg);
  transform: rotate(45deg);
}

.FlowerBG {
  width: 200%;
  height: 200%;
  top: 20%;
  left: 25%;
  transform: rotate(25deg);
  background-image: url(../../../assets/images/flower_yellow.svg);
}

.CloverBG {
  width: 200%;
  height: 200%;
  top: 20%;
  left: -5%;
  background-image: url(../../../assets/images/clover_yellow.svg);
}

.SectionTitle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.SectionTitleText {
  font-size: 18px;
  font-weight: bold;
  color: #412F0B;
}

.SectionBorder{
  width: 125px;
  margin: 5px 0;
}

.Statement {
  font-size: 16px;
  font-weight: bold;
  color: #412F0B;
  padding:8px 15px;
  background-image: url(../../../assets/images/quotes_start.svg), url(../../../assets/images/quotes_end.svg);
  background-position: left top, right bottom;
  background-repeat: no-repeat;
  background-size: 31px auto;
}

.Description {
  width: 95%;
  font-size: 14px;
  line-height: 1.85em;
  color: #412F0B;
  margin: 20px auto;
}

@media (min-width: 375px) {
  .Statement {
    font-size: 18px;
    padding:8px 30px;
  }  
}

@media (min-width: 530px) {
  .Section {
    max-width: 800px;
  }
  .SectionTitleText, .Statement{
    font-size: 20px;  
  }
  .TagBG {
    top: 60%;
    left: 40%;
  }
  .FlowerBG {
    top: 60%;
    left: 40%;
  }
  .Description {
    font-size: 16px;  
  }  
}

@media (min-width: 768px) {
  .Section {
    margin: 40px auto;
    max-width: 950px;
  }
  .SectionTitleText, .Statement{
    font-size: 22px;  
  }
  .TagBG {
    top: 60%;
    left: 60%;
  }
  .FlowerBG {
    top: 60%;
    left: 60%;
  }
  .HeartBG {
    width: 180%;
    top: 120%;
    left: 5%;
    transform: rotate(20deg);  
  }
  .Description {
    font-size: 18px;  
  }
}

@media (min-width: 1024px) {
  .Section {
    margin: 60px auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .SectionTitleBox{
    flex: 1;
    margin-right: 15px;
  }
  .HeartBG {
    width: 200%;
    top: 80%;
    left: -10%;
    transform: rotate(20deg);  
  }
  .FlowerBG {
    top: 60%;
    left: 40%;
  }
  .Description {
    flex: 1;
    margin: 0 0 0 15px;  
  }
}

@media (min-width: 1500px) {
  .Section {
    margin: 80px auto;
  }

  .SectionTitleBox{
    margin-right: 20px;
  }
  .Description {
    font-size: 22px;
    margin: 0 0 0 20px; 
  }  
}