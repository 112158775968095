.MarginTop15 {
    margin-top: 15px;
}

.Left {
    text-align: left;
}

.Step, .StepLeft {
    width: 60%;
    min-width: 152px;
    max-width: 192px; 
    display: flex;
}

.StepLeft {
    margin-left:auto;    
}

.StepContent {
    flex:1;
    /* border: 1px solid #41BEEB; */
    border-radius: 5px;
    align-self: flex-end;
    /* background-color: #ffffff;    */
}

.BgWhite {
    background-color: #ffffff;    
}
.BgSpecial {
    background-color: #f3cb86;
}

.Pointer {
    width: 12px;
    /* height: 10px; */
    margin: 8px 0 auto 0;
}

.StepTitle {
    padding:5px;
    font-size: 0.8rem;
    text-align: left;
    display: flex;
}

.StepText {
    padding:5px;
    font-size: 0.8rem;
    text-align: left;
}

.TaguJamLogo {
    background-color: #ffffff;
    width:1.2rem;
    height:1.2rem;
    border-radius: 50%;
    border: 1px solid #ffffff;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right: 5px;
}

@media (min-width:1024px) {
    .Step, .StepLeft {
        width: 70%;
        min-width: 180px;
        max-width: 300px; 
    }
    .Pointer {
        width: 18px;
        margin-top: 6px;
    }   
}