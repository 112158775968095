@import '../../../constants/Colors.scss';

.Section {
  width: 90%;
  min-width: 300px;
  max-width:770px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SectionTitleBox{
  width: 100%;
  height: 150px;
  background-color: #d4e09b;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

.SectionTitleBG{
  width: 150%;
  height: 150%;
  background-repeat: no-repeat;
  transform: rotate(15deg);
  position: absolute;
}

.TagBG {
  top: 20%;
  left: 25%;
  background-image: url(../../../assets/images/tag_green60x50.svg);
}

.FlowerBG {
  width: 200%;
  height: 200%;
  top: 20%;
  left: 25%;
  transform: rotate(25deg);
  background-image: url(../../../assets/images/flower_green.svg);
}

.CloverBG {
  width: 200%;
  height: 200%;
  top: 20%;
  left: -5%;
  background-image: url(../../../assets/images/clover_green.svg);
}

.SectionTitle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.SectionTitleText {
  font-size: 18px;
  font-weight: bold;
  color: #412F0B;
}

.SectionBorder{
  width: 125px;
  margin: 5px 0;
}

.Statement {
  font-size: 14px;
  font-weight: bold;
  color: #412F0B;
  padding:8px 10px;
  background-image: url(../../../assets/images/quotes_start.svg), url(../../../assets/images/quotes_end.svg);
  background-position: left top, right bottom;
  background-repeat: no-repeat;
  background-size: 31px auto;
}

.Description {
  width: 95%;
  font-size: 14px;
  line-height: 1.85em;
  color: #412F0B;
  margin: 20px auto;
}

.TaggerJammer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-bottom: 80px;
  background-image: url(../../../assets/images/heart_pink.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.Stecker{
  height: 100%;
  margin: 0 10px;
}

.Middle {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
}

.Table {
  width: 90%;
  font-size:1rem; 
  display: flex;
  flex-wrap: wrap;
  max-width:770px;
  margin:0px auto 30px auto;
}

.Table dt
{
  padding: 10px;
  border: 1px solid #efefef;
  width: 100%;
  min-height:20px;
  background-color: #e8f3f3;
}

.Table dd 
{
  padding: 10px;
  border: 1px solid #efefef;
  width: 100%; 
  min-height:20px;   
}

.RadiusLeftTop, .RadiusRightTop, .RadiusLeftBottom, .RadiusRightBottom {
  border-radius: 0
}

@media (min-width: 414px) {
  .Statement {
    font-size: 16px;
    padding:8px 15px;
  }   
}

@media (min-width: 530px) {
  .Section .Table {
    max-width: 800px;
  }
  .SectionTitleText, .Statement{
    font-size: 20px;  
  }
  .Statement {
    font-size: 18px;
    padding:8px 30px;
  }  
  .TagBG {
    top: 60%;
    left: 40%;
  }
  .FlowerBG {
    top: 60%;
    left: 40%;
  }
  .Description {
    font-size: 16px;  
  }
  
  .TaggerJammer {
    height: 130px;
  }
  .Table dt, .Table dd 
  {
    width: 50%;
    min-height: 80px;
  }
  .Table dt
  {
    background-color: #ffffff;  
  }
  
  .RadiusLeftTop {
    border-radius: 15px 0 0 0;
  }
  
  .RadiusRightTop {
    border-radius: 0 15px 0 0;
  }
  
  .RadiusLeftBottom {
    border-radius: 0 0 0 15px;
  }
  
  .RadiusRightBottom {
    border-radius: 0 0 15px 0;
  }
}

@media (min-width: 768px) {
  .Section, .Table {
    margin: 40px auto;
    max-width: 950px;
  }
  .SectionTitleText, .Statement{
    font-size: 22px;  
  }
  .TagBG {
    top: 60%;
    left: 60%;
  }
  .FlowerBG {
    top: 60%;
    left: 60%;
  }
  .Description {
    font-size: 18px;  
  }
  .TaggerJammer {
    height: 150px;
  }
}

@media (min-width: 1024px) {
  .Section {
    margin: 60px auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .SectionTitleBox{
    flex: 1;
    margin-right: 15px;
  }
  .FlowerBG {
    top: 60%;
    left: 40%;
  }
  .Description {
    flex: 1;
    margin: 0 0 0 15px;  
  }
  .TaggerJammer {
    height: 180px;
  }
  .Middle h5, .Middle p 
  {
    font-size: 1rem;
  }
}

@media (min-width: 1500px) {
  .Section, .Table  {
    margin: 80px auto;
    max-width: 1250px;
  }

  .SectionTitleBox{
    margin-right: 20px;
  }
  .Description {
    font-size: 22px;
    margin: 0 0 0 20px; 
  }  
}
