.DownloadsActions {
    width: 95%;
    max-width: 400px;
    margin: 15px auto;
    text-align: center;
}

.ActionButtons {
    display: flex;
    flex-flow: wrap;
    width: 95%;
    max-width: 640px;
    margin: 15px auto;
    justify-content: center;
    /* justify-content: space-between; */
    align-items: center;
}

.ActionButtons p{
    font-size: 0.97rem;
    flex-flow: nowrap;
    max-width: 90%;
    text-align: center;
    margin: 2.5px auto;
}

.ActionButtons h4{
    max-width: 90%;
    margin: 10px auto;
    text-align: center;
}

.ActionButton {
    width: 90%;
    min-width: 250px;
    display: flex;
    flex-flow: wrap;
    margin: 0 0 15px 0;
    padding:20px 15px;
    border: 1px solid #41BEEB;
    border-radius: 15px;
    color: #41BEEB;
    background-color:#fff;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    box-shadow: 1px 1px 5px #88888871;
}

.ActionButton:hover {
    cursor: pointer;
    color: #fff;
    background-color:#41BEEB;    
}

.ActionButton i {
    flex: 1;
    font-size: 1.5rem;
    padding-top:0.1rem;
    padding-right:5px;
    text-align: left;
    
}

.ActionButton label {
    flex: 8;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
}

.ActionButton span {
    flex: 1;
    /* margin:0;
    padding:0; */
    text-align: right;
    font-size: 1.5rem;
}

/* .InlineP {
    display: block;
    margin: 3px auto;
} */



@media (min-width:400px){
     
}

@media (min-width:600px){
    .ActionButtons {
        justify-content: space-between;
    }
    .ActionButton {
        width: 45%;
        min-width: 250px;
    }
}
