/* Tab */
.TabContainer {
    width: 100%;
    min-width: 300px;
    margin: 10px auto 15px auto;
    display: flex;
}

.BuySellSelector {
    width: 100%;
    display: flex;
    border-bottom:1px solid #D6D6D6;
    padding:20px 0 8px 0;
}

.faicon{
    margin-right: 5px;
}

.SelecterLabel {
    display: flex;
    align-items: center;
    margin-right: 15px;
    color:#707070;
    font-size: 1rem;
    font-weight: bold;
    opacity: 0.5;
}

.SelectActive, .SelecterLabel:hover{
    color:#41BEEB;
    opacity:1;
}

.TabLabel {
    font-size: 0.7rem;
    font-weight: bold;
    color: #707070;
    flex:1;
    padding: 8px;
    opacity: 0.5;
    text-align: center;
    transition: 0.6s ease;
    flex:1;
}

.TabLabel:last-child {
    padding-right:0;
}
.TabLabel:first-child {
    padding-left:0;
}

.TabBorder {
    margin-top:5px;
    height:8px;
    background-color: #707070;
}

.ActiveTab {
    background-color: #41BEEB;
}

.TabLabel:hover > div.TabBorder {
    background-color: #41BEEB;    
}

.Active, .TabLabel:hover{
    color: #41BEEB;
    opacity: 1;
}

.CatIcon {
    font-size:16px;
}

.MenuToggleBtn {
    position: absolute;
    left:0;
    top:200;
    width:30px;
    color: #fff;
    font-size: 0.9rem;
    font-weight: bold;
    background-color: #41BEEB;
    border-radius: 0px 5px 5px 0;
    padding:5px 5px 10px 5px;
    display: block;
}

.faiconVertical {
    margin-left: 3px;  
    font-size: 18px; 
}

.HelpContent{
    width: 100%;
    display: flex;
}

.DeskTopOnly {
    display: none;
}
.MobileOnly {
    display: block;
}

.HelpArticle {
    background-color: #fff;
    width: 100%;
    min-width: 280px;
    padding: 10px;
    border-radius: 5px;
    flex:3;
    margin-left:20px;
    margin-bottom:15px;
}

.NoMarginLeft {
  margin-left:0px;  
}

.PrevNextContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; 
    padding: 0px 10px;  
}

.Prev, .Next {
    font-size: 0.8rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 10px 20px 15px 20px;
    opacity: 1;
}

.Disable {
    opacity: 0.5;    
}

.Prev:hover, .Next:hover {
    color: #44a7cb;
    opacity: 1;    
}

.Prev:before, .Next:after {
    font-family: "FontAwesome";
    font-size: 13px;
}

.Prev:before {
    content: '\f0d9'; /* fontawesome caret-down sign */
    float: left;
    margin-right: 5px;
}

.Next:after {
    content: '\f0da'; /* fontawesome caret-down sign */
    float: right;
    margin-left: 5px;
}

@media (min-width: 600px) {
    .HelpArticle {
        margin-left: 0;
    }
    .MobileOnly{
        display: none;
    } 
    .DeskTopOnly {
        display: block;
    }  
}

