.StepCenterContent {
    flex:1;
    align-self: flex-end;
    background-color:#f5f5f5;   
}

.StepContent {
    flex:1;
    /* border: 1px solid #D6D6D6; */
    border-radius: 5px;
    align-self: flex-end;
    background-color: #ffffff; 
    max-width: 280px; 
}

.BgLightGray {
    background-color: #D6D6D6;    
}
.BgSpecial {
    background-color: #f3cb86;
}

.StepTitle {
    padding:10px;
    font-size: 1rem;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
