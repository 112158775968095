.DownloadBtns {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding:0;
    margin:0;
}

.Center {
  justify-content:center;
}

.Left {
  justify-content:flex-start;
}

.BlogAlign {
  justify-content:center;  
}

.BtnStyles {
    width: 180px;
    max-width: 45%;
}

@media (min-width: 768px) {
  .BlogAlign {
    justify-content:flex-start;  
  } 
}