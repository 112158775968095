.TextLink {
    margin:0px auto;
    text-align: center;
    text-decoration: none;
    color: #41BEEB;
}

.TextLink:hover {
    cursor: pointer;
    color: rgb(77, 166, 199);
}