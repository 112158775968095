@import '../../../constants/Colors.scss';

.Pagenation {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $contentFontColor;
}

.PageNavText {
  display: block;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  margin: 20px 5px;
  opacity: 0.5;
}

.PageNav, .PageNavActive {
  width: 26px;
  height: 26px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 2px;
  opacity: 0.5;
}

.PageNavText:hover, .PageNav:hover, .PageNavActive:hover{
  opacity: 1;
}

.PageNavActive {
  color: $primaryButtonBGColor;
  border-radius: 18px;
  background-color: #F0F3E5;
  opacity: 1;
}

@media (min-width: 1024px) {
  .PageNavText {
    font-size: 14px;
    line-height: 26px;
    margin: 30px 8px;
  }

  .PageNav, .PageNavActive {
    width: 30px;
    height: 30px;
    font-size: 18px;
    margin: 4px;
  }
  
  .PageNavActive {
    border-radius: 20px;
  }
}