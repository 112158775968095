.Toolbar {
    display:flex;
    min-width: 320px;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 30px 10px;
    border-bottom: 1px solid #eaeaeb;
}

.Brand {
    display:flex;
    justify-content: flex-start;
    margin:0px;
    padding:0px;
    width: 135px;
} 

@media (max-width: 530px) {
    .DesktopOnly {
        display: none;   
    }
    .Brand {
        width: 100%;
        justify-content: center;
    }
}

@media print {
    .Toolbar {display:none}    
}
