.SystemFlow {
    width: 100%;
    margin: 0px auto;
    background-color:#f5f5f5;
    padding-bottom: 50px; 
}

.DealFlowContents {
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}

.FlowHead {
    width: 100%;
    min-width: 320px;
    display:flex;
    margin-bottom: 15px;
    font-size: 0.85rem;    
}

.FlowRow {
    width: 100%;
    min-width: 320px;
    margin: 0 auto;
    background-image: url(../../../assets/images/gray_border5.svg);
    background-repeat: repeat-y;
    background-position: center;
    background-size: 5px 5px;
    display:flex;
    padding-bottom: 35px
} 

.FlowColum {
    flex:1
}

.StepImageBox {
   width: 100%; 
   text-align: center;
}
.StepImage {
    width: 80%;
}

.StepCenterImage {
    width: 50%;
}

.StepText {
    padding:10px;
    font-size: 0.8rem;
    text-align: left;
    width: 100%;
}

.Roles {
    background-color: rgb(194, 230, 243);
    border-radius: 25px;
    padding:15px;
    font-size: 1rem;
    font-weight: bold;
    color: rgba(63, 62, 62, 0.7);
    margin: 0 auto;
    min-width: 200px;
    text-align: center;
}


.HelpCat {
    background-image: url(../../../assets/images/tag_help_65x50.svg);
    padding-top: 5px; 
}

.TeachCat {
    background-image: url(../../../assets/images/tag_teach_65x50.svg); 
    padding-top: 5px;  
}

.RentCat {
    background-image: url(../../../assets/images/tag_rent_65x50.svg);
    padding-top: 5px;    
}

.MakeCat {
    background-image: url(../../../assets/images/tag_make_65x50.svg);
    padding-top: 5px;    
}

.CatIcon {
    flex:1;
    width: 50px;
    height: 38.462px;
    font-size: 18px;
    color: white;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px 38.462px;
}


.ActionButtons {
    display: flex;
    flex-flow: wrap;
    width: 95%;
    max-width: 640px;
    margin: 15px auto;
    justify-content: center;
    align-items: center;
}

.ActionButton {
    width: 90%;
    min-width: 250px;
    display: flex;
    flex-flow: wrap;
    margin: 0 0 15px 0;
    padding:20px 15px;
    border: 1px solid #41BEEB;
    border-radius: 15px;
    color: #41BEEB;
    background-color:#fff;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    box-shadow: 1px 1px 5px #88888871;
}

.ActionButton:hover {
    cursor: pointer;
    color: #fff;
    background-color:#41BEEB;    
}

.ActionButton i {
    flex: 1;
    font-size: 1.5rem;
    padding-top:0.1rem;
    padding-right:5px;
    text-align: left;
    
}

.ActionButton label {
    flex: 8;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
}

.ActionButton span {
    flex: 1;
    /* margin:0;
    padding:0; */
    text-align: right;
    font-size: 1.5rem;
}


@media (min-width: 321px) {
    .CatIcon {
        flex:1;
        width: 65px;
        height: 50px;
        font-size: 23px;
        background-size: 65px 50px;
    }
    .SystemFlow{
        width: 95%;
        min-width: 320px;
    }
    .Arrow {
        width: 10%;
        max-width: 65px;
        min-width: 20px;
    }
    .ArrowDown{
        width: 10%;
        min-width: 18px; 
        margin:15px auto   
    }
    .LogoImage {
        width: 58%;
        max-width: 50px;
    }
}

@media (min-width:600px){
    .ActionButtons {
        justify-content: space-between;
    }
    .ActionButton {
        width: 45%;
        min-width: 250px;
    }
    .FlowRowData{
        font-size: 0.82rem;
    }
    .ArrowDown{
        width: 10%;
        max-width: 30px; 
    }
}

@media (min-width: 768px) {
    .SystemFlow {
        width: 95%;
        max-width: 700px;
    }
    .FlowRowData{
        font-size: 0.9rem;
    }
    .LogoImage {
        width: 60%;
        max-width: 65px;
        padding: 5px;
    }
    .FlowRowData i {
        display:block;
        padding: 8px;
        font-size: 40px;
    }
}

@media (min-width: 1024px) { 
    .SystemFlow {
        width: 90%;
        max-width: 800px;
    }
    .LogoImage {
        width: 60%;
        max-width: 70px;
        padding: 10px;
    }
    .StepImage {
        width: 60%
    }    
}

@media (min-width: 1500px) {
}