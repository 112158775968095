@import '../../constants/Colors.scss';

.ThumbmailContainer{
    cursor: pointer;
    width:80px;
    min-width: 80px;
    border-radius:5px;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.26);
    transition: 0.3s;
}

.ThumbmailContainer:hover{
    opacity: 0.5;
}

.GigImage {
    width: 100%;
    height: 100%;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius:5px;
    position:relative;
}

.SubCatBG { 
    width:80px;
    height:15px;
    background-color:$white;
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    opacity:0.8;
    position:absolute; 
}

.SubCatTitle{ 
    width:80px; 
    height:15px; 
    position:absolute;
    display:flex;
    flex-direction: row;
    justify-content:center; 
    align-items:center;
} 

.SubCatText { 
    color: $tabBGcolor;
    font-size: 12px; 
    font-weight:bold;
}

.PriceTagBG {
    width: 100%;
    position:absolute;
    opacity: 0.9;
    bottom:0;
}

.Price { 
    width:100%;
    height:15px;
    position:absolute;
    bottom:0;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
}

.PriceText{
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items:center; 
    color: $white;
    font-size: 10px;
    font-weight:bold;
}

.MaxPerson{ 
    color: $white;
    font-size: 8px;
    font-weight:normal;
}