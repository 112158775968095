.PageHeader {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    text-align: center;
}

.DealFlow {
    width: 100%;
    margin: 0 auto;
}

/* Tab */
.TabContainer {
    width: 100%;
    min-width: 320px;
    margin: 0px auto;
    display: flex;
}

.TabLabel {
    position: relative;
    font-size: 0.7rem;
    font-weight: bold;
    color: #41BEEB;
    border-bottom: 3px solid #41BEEB;
    border-top: 1px solid #41BEEB;
    border-left: 1px solid #41BEEB;
    border-right: 1px solid #41BEEB;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    flex:1;
    height: 60px;
    padding: 8px;
    text-align: center;
    /* background-color:#fff; */
    transition: background-color 0.6s ease;
    transition: color 0.6s ease;
}

.Active, .TabLabel:hover{
    background-color: #41BEEB;
    color: white;
    border-bottom: 3px solid #f5f5f5;
    /* border: none; */
}

.TabIndicator {
    width: 16px;
    height: 8px;
    bottom: 0px;
    transform: translateX(-50%);
    position: absolute;
}

.CatIcon {
    font-size:18px;
}

.HelpCat {
    background-image: url(../../assets/images/tag_help_65x50.svg);
}

.TeachCat {
    background-image: url(../../assets/images/tag_teach_65x50.svg); 
}

.RentCat {
    background-image: url(../../assets/images/tag_rent_65x50.svg); 
}

.MakeCat {
    background-image: url(../../assets/images/tag_make_65x50.svg);  
}

.TabGap {
    width: 3px;
    border-bottom: 3px solid #41BEEB;
}

.TabGapRemain {
    flex:1;
    border-bottom: 3px solid #41BEEB;
}
/* Tab Ends */

.ServiceTypeLabel {
    display: flex;
    align-items: center;
    margin-right: 15px;
    color:#707070;
    font-size: 0.89rem;
    font-weight: bold;
    opacity: 0.5;
}

.ServiceTypeActive, .ServiceTypeLabel:hover{
    color:#41BEEB;
    opacity:1;
}

.DealFlowContents {
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color:#f5f5f5; 
    text-align: center;
}

.FlowHead {
    width: 100%;
    min-width: 320px;
    display:flex;
    margin-bottom: 15px;
    font-size: 0.85rem;    
}

.FlowRow {
    width: 100%;
    min-width: 320px;
    margin: 0 auto;
    background-image: url(../../assets//images/gray_border5.svg);
    background-repeat: repeat-y;
    background-position: center;
    background-size: 5px 5px;
    display:flex;
    padding-bottom: 35px
} 

.FlowColum {
    flex:1
}

.CancelText{
    flex-flow: column;
}

.CancelText p{
    padding:5px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    background-color: #fff;
    color: #ED0B0B;
    font-size: 0.75rem;
    margin: 0 auto 15px auto;
}

.PaymentAvlText {
    color: #ED0B0B;    
}

.WarningNote {
    font-size:0.65rem;
    line-height: 0.9rem;
    color:#ED0B0B;
}

@media (min-width: 321px) {
    .DealFlow, .DealFlowContents {
        width: 90%;
        min-width: 320px;
    }
}
@media (min-width: 322px) {
    .DealFlowContents {
        border-right: 1px solid #dcdcdc;
        border-left:1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

@media (min-width: 600px) {
    .TabGap{
        width: 10px;
    }
    .TabContainer, .DealFlowContents {
        max-width: 1024px;
    }    
}
