.TabContainer {
    width: 100%;
    margin: 0px auto;
    display: flex;
}

.TabLabel {
    position: relative;
    font-size: 1.05rem;
    font-weight: bold;
    color: #41BEEB;
    border-bottom: 3px solid #41BEEB;
    border-top: 1px solid #41BEEB;
    border-left: 1px solid #41BEEB;
    border-right: 1px solid #41BEEB;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 155px;
    height: 54px;
    padding: 10px;
    text-align: center;
    transition: background-color 0.6s ease;
    transition: color 0.6s ease;
}

.Active, .TabLabel:hover{
    background-color: #41BEEB;
    color: white;
    border: none;
}

.TabIndicator {
    width: 20px;
    height: 10px;
    bottom: 0px;
    margin-left: -10px;
    position: absolute;
}

.TabGap {
    width: 10px;
    border-bottom: 3px solid #41BEEB;
}

.TabGapRemain {
    flex:1;
    border-bottom: 3px solid #41BEEB;
}

.SafeSystemArea {
    width: 100%;
    margin: 30px auto 0px auto;
    color: rgb(63, 62, 62);
    margin: 15px auto 0 auto;
}

.SafeSystemContainer{
    width: 100%;
    display: flex;
    flex-flow: column;
    text-align: center;
    padding: 10px;
    margin: 0 0 30px 0;
    background-color: #ffffff;
    border-radius: 0px;
    background-image: url(../../../assets/images/security_bg.svg); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80% 80%;
    box-shadow: 1px 1px 5px #88888871;
}

.ListText {
    font-size: 0.9rem;
    padding: 10px 0;
}

.SecurityImage {
    width: 60%;
    border-radius: 15px;
    display: none;
    padding: auto;
    margin: auto 20px auto auto;
    opacity: 0.5;
}

@media (min-width: 321px) {
    .TabContainer, .SafeSystemArea {
        width: 95%;
        min-width: 320px;
    }
    .SafeSystemContainer{
        padding: 15px;
        border-radius: 15px;  
    }    
}

@media (min-width: 768px) {
    .TabContainer, .SafeSystemArea {
        max-width: 95%;
    }
    .TabGap {
        width: 20px;
    } 
    .SecurityImage {
        max-width:200px;
        display: block;
    }
    .SafeSystemContainer{
        flex-flow: row;
        justify-content:space-between;
        align-items: flex-start;
        background-image: none;
    }
}

@media (min-width: 1024px) { 
    .TabContainer, .SafeSystemArea {
        width: 90%;
        max-width: 1200px;
    }
    .TabLabel {
        width: 250px;
        height: 60px;
        padding: 15px;
    } 
    .SafeSystemContainer{
        padding: 15px;
    }    
}

@media (min-width: 1500px) {
    .SafeSystemContainer{
        padding: 20px;
    }  
}