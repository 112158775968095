@import '../../../constants/Colors.scss';

.PodBig {
  width: 100%;
  height: 150px;
  background-color: #F0F3E5;
  min-width: 320px;
  margin-top:20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;
  text-decoration: none;
}

.PodBig:hover {
  opacity: 0.5;
}

.PodImageBig {
  width:150px;
  height:150px;
}

.PodImgBig {
  width: 100%;
  height: 100%;
}

.PodBigTextArea {
  padding: 10px;
  flex:1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 3em;
}

.PodBigTextHead {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $contentFontColor;
}

.PodBigText {
  font-size: 12px;
  line-height: 22px;
  white-space: pre-wrap;
  color: $contentFontColor;
}

.Details {
  color: $primaryButtonBGColor;
}

@media (min-width: 375px) {
}

@media (min-width: 530px) {
  .PodBig {
    height: 220px;
  }

  .PodImageBig {
    width:220px;
    height:220px;
  }

  .PodBigTextHead {
    font-size: 20px;
  }
  
  .PodBigText {
    font-size: 16px;
    line-height: 27px;
  }
}

@media (min-width: 768px) {
  
  /* big pod */
  .PodBig {
    min-width: 100%;
    max-width: 100%;
    height: auto;
    flex-direction: column;
  }
  
  .PodImageBig {
    width: 100%;
    height: auto;
  }

  .PodBigTextHead {
    font-size: 16px;
  }

  .PodBigText {
    font-size: 14px;
    line-height: 22px;
  }
  /* big pod */
  /* Blog Area */
}

@media (min-width: 1024px) {
  /* pod big */
  .PodBigTextArea {
    padding: 15px;
  }

  .PodBigTextHead {
    font-size: 22px;
    margin-bottom: 8px;
  }
  
  .PodBigText {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (min-width: 1300px) {
}

@media (min-width: 1500px) {
  /* pod big */
  .PodBigTextArea {
    padding: 20px;
  }

  .PodBigTextHead {
    font-size: 27px;
    margin-bottom: 10px;
  }
  
  .PodBigText {
    font-size: 22px;
    line-height: 35px;
  }
  /* pod big */
}