@import '../../../constants/Colors.scss';

.PriceTagSvg {
    background-position:left;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
	margin: 0;
    padding: 0;
    display:flex;
    flex-direction: row;
}

.HELP {
    background-color: $mainMenuBgColorHELP;
}

.TEACH {
    background-color: $mainMenuBgColorTEACH;
}

.RENT {
    background-color: $mainMenuBgColorRENT;
}

.MAKE {
    background-color: $mainMenuBgColorMAKE;
}