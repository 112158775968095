@import '../../constants/Colors.scss';

.Content {
  background-color: $white;
  width: 100%;
  margin: 15px auto;
  padding: 10px;
  border-radius: 5px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
}

.CertContainer {
  border-radius: 3px;
  width: 100%;
  min-width: 280px;
  max-width: 300px;
  padding: 10px 0px 0px 0px;
  border: 1px solid $primaryButtonBGColor;
  background-color: $primaryButtonBGColor;
  margin: 20px auto;
}

.UserProfileContainer {
  width: 100%;
  background-color: $primaryButtonBGColor;
  border-radius: 8px;
}