@import '../../../constants/Colors.scss';

.NAContainer{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} 
.NAContentContainer{
    width:46%;
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center
}
.TagSvgContainer{
    width:130px; 
    height:100px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    margin-bottom:10px; 
}
.MessageContainer{
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    margin-left: 10px
}
.MessageText{
    font-size:15px; 
    color:$contentFontTintColor;
    flex: 1
}
.ButtonContainer{
    width: 150px; 
    height:40px; 
    border-radius:20px; 
    border:1px solid $primaryButtonBGColor;;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    margin-top:10px;
    margin-bottom:10px; 
}
.ButtonText{
    font-size:15px; 
    font-weight:bold; 
    color:$primaryButtonBGColor
}