@import '../../../constants/Colors.scss';

.SmartBanner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  background-color: $mainMenuInActiveIconColor;
  height: 75px;
  box-shadow: 0 0px 5px;
}

.CloseContainer{
  //font-size: 0.75rem;
  color:$contentFontTintColor;
  width: 20px;
  min-width: 20px;
  text-align: left;
}

.Image{
  width: 55px;
  height: 55px;
  min-width: 55px;
  background-color: $mainMenuIconColor;
  border-radius: 8px;
  background-image: url(../../../assets/images/tagujam_logo.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 45px 45px;
}

.ContentContainer {
  flex:3;
  padding: 0 10px;  
}

.Title{
  font-size: 0.75rem;
  font-weight: bold;
  color:$primaryButtonTextColor;
}

.Description{
  font-size: 0.75rem;
  color:$tintColorBGColor;
  white-space: nowrap;
}

.BtnContainer{
  width: 80px;
  min-width: 65px;
}