@import '../../constants/Colors.scss';

/* common */
.SpinnerBox {
    width:100%;
    min-height:280px;
    display:flex;
    align-items:center;
    justify-content:center;
}

/* custom */
.HELP {
    background: $mainMenuBgColorHELP;
    background-color: $mainMenuBgColorHELP;
}

.TEACH {
    background: $mainMenuBgColorTEACH;
    background-color: $mainMenuBgColorTEACH;
}

.RENT {
    background: $mainMenuBgColorRENT;
    background-color: $mainMenuBgColorRENT;
}

.MAKE {
    background: $mainMenuBgColorMAKE;
    background-color: $mainMenuBgColorMAKE;
}

.PageContent {
    display:flex;
    flex-direction: column;
    flex:1;
    padding: 15px 0;
}

.SubCat {
    width:100%;
    text-align:center;
    padding: 0.5rem;
    background:$tintColorBGColor;
}

.TopNavText {
    font-size:0.85rem;
}

.TopNavText:hover{
    cursor: pointer;
    opacity: 0.5;
}

.MainCat {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 300px;
    max-width: 320px;
    color: $white;
    text-align: center;
    padding: 0.6rem;
}

.Card {
    min-width: 300px;
    max-width: 320px;
    background: $white;
    margin:20px 0 0 0;
}

.ImageContainer {
    min-width: 300px;
    max-width: 320px;
    height: 240px;
    width: 100%;
    margin:0 auto; 
    position:relative;
}

.IndiCatorContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.IndicatorText {
    font-size: 12px;
    font-weight: bold;
}

.BudgeBox{
    border-radius:3px;
    padding:0.4em; 
    align-items:center;
    justify-content:center
}

@media (min-width: 321px) {
    .MainCat, .Card {
        width: 90%;
        margin:0 auto 0 auto;
        border-radius: 5px 5px 0px 0px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    }
    .Card {
        border-radius: 5px 5px 5px 5px;
        margin:20px auto 0 auto;
    }
    .Cart:first-child{
        border-radius: 0px 0px 5px 5px;   
    }
    .ImageContainer {
        width: 90%;
        margin:0 auto 0 auto;
    }    
}

@media (min-width: 375px) {
    .MainCat, .Card {
        max-width: 350px;
    }
    .ImageContainer {
        max-width: 320px;
    }      
}

@media (min-width: 531px) {
    .MainCat, .Card {
        max-width: 500px;
    }
    .ImageContainer {
        max-width: 480px;
        height: 360px;
    }  
    .PageContent {
        padding: 20px 0;
    }
}

@media (min-width: 766px) {
    .MainCat, .CardHead, .Card{
        max-width: 650px;
    }
    .ImageContainer {
        max-width: 640px;
        height: 360px;
    }  
    .PageContent {
        padding: 30px 0;
    }
}

@media (min-width: 1024px) {
    .MainCat, .CardHead, .Card {
        max-width: 800px;
    }
}