.WhatDescription {
    color: rgb(63, 62, 62);
    width: 100%;
    margin: 15px auto 0px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
}

.DescHeader {
    display: flex;
    align-items: center;
    background-color:#d0e9f7;
    padding: 8px;
}

.Description {
    width: 320px;
    padding: 0;
    margin-bottom: 30px;
    background-color: #fff;
    box-shadow: 1px 1px 5px #88888871;
}

.Description p{
    font-size: 0.9rem;
    line-height: 1.5rem;
    padding: 15px;   
}    

.HelpCat {
    background-image: url(../../../assets/images/tag_help_65x50.svg);
    padding-top:5px;   
}

.TeachCat {
    background-image: url(../../../assets/images/tag_teach_65x50.svg); 
    padding-top:5px;   
}

.RentCat {
    background-image: url(../../../assets/images/tag_rent_65x50.svg);
    padding-top:5px;    
}

.MakeCat {
    background-image: url(../../../assets/images/tag_make_65x50.svg);
    padding-top:5px;    
}

.CatIcon {
    width: 50px;
    height: 38.462px;
    font-size: 18px;
    color: white;
    text-align: center;
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    background-color:#d0e9f7;
}

.MainContentImage {
    margin: 0;
    border-radius: 50%;
}

.WhatImage {
    width:60%;
    margin: 15px auto 0 auto;
}   

.WhatImage img {
    width:100%;
}

@media (min-width: 321px) {
    .WhatDescription {
        justify-content:center; 
        max-width: 320px;
    }
    .DescHeader {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .Description {
        border: 1px solid #d0e9f7;
        border-radius: 8px;
    }
}

@media (min-width: 768px) {
    .WhatDescription{
        justify-content:space-between; 
        max-width: 680px;
    } 
}

@media (min-width: 1500px) {
    .WhatDescription{
        justify-content:space-between; 
        max-width: 1360px;
    }   
}
