@import "../../constants/Colors.scss";

.ConfirmCoveredAreaContainer {
    width: 90%;
    max-width: 304px;
    max-height: 90%;
    border-radius: 5px;
    padding:8px 8px 20px 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    background-color: $white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 400;
}

.CloseBtnContainer{
    width:100%;
    display:flex;
    flex-direction:row; 
    height:30px; 
    align-items:center; 
    justify-content:flex-end;
    padding:10px 0px;
}

.Open {
    display: block;
}

.Close {
    display: none;
}

.ServiceLocContainer{
    width:49%; 
    display: flex;
    flex-direction:column;
    justify-content:space-evenly; 
    align-items:flex-start
}

.CoveredAreaContainer{
    width:49%;
    display: flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:flex-end
}

.RowContainer{
    width:100%;
    display: flex;
    justify-content:flex-start;
    align-items:flex-start;  
    padding-top:20px; 
    padding-bottom:10px;
    flex-direction:row
}

.CityListContainer {
    width: 100%; 
    padding-top:15px;
    padding-bottom:5px;
    display: flex;
    flex:1;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content: space-between
}

.AreaTable {
    width:100%;
    padding-bottom:20px;
    height:65%;
    max-height:260px;
    overflow-y: auto
}

@media (min-width: 531px) {
    .ConfirmCoveredAreaContainer {
        max-width: 400px;
    }
}

@media (min-height: 667px) {
    .AreaTable {
        max-height:300px;
    }
}